/**=============font-family=============**/

@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNovaBold.eot");
  src: url("../fonts/ProximaNovaBold.eot") format("embedded-opentype"),
    url("../fonts/ProximaNovaBold.woff2") format("woff2"),
    url("../fonts/ProximaNovaBold.woff") format("woff"),
    url("../fonts/ProximaNovaBold.ttf") format("truetype"),
    url("../fonts/ProximaNovaBold.svg#ProximaNovaBold") format("svg");
  font-weight: bold;
}

@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNovaReg.eot");
  src: url("../fonts/ProximaNovaReg.eot") format("embedded-opentype"),
    url("../fonts/ProximaNovaReg.woff2") format("woff2"),
    url("../fonts/ProximaNovaReg.woff") format("woff"),
    url("../fonts/ProximaNovaReg.ttf") format("truetype"),
    url("../fonts/ProximaNovaReg.svg#ProximaNovaReg") format("svg");
  font-weight: normal;
}

@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/proximanovasemiboldwebfont.eot");
  src: url("../fonts/proximanovasemiboldwebfont.eot") format("embedded-opentype"),
    url("../fonts/proximanovasemiboldwebfont.woff2") format("woff2"),
    url("../fonts/proximanovasemiboldwebfont.woff") format("woff"),
    url("../fonts/proximanovasemiboldwebfont.ttf") format("truetype"),
    url("../fonts/proximanovasemiboldwebfont.svg#proximanovasemiboldwebfont") format("svg");
  font-weight: 500;
}

@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNovaXbold.eot");
  src: url("../fonts/ProximaNovaXbold.eot") format("embedded-opentype"),
    url("../fonts/ProximaNovaXbold.woff2") format("woff2"),
    url("../fonts/ProximaNovaXbold.woff") format("woff"),
    url("../fonts/ProximaNovaXbold.ttf") format("truetype"),
    url("../fonts/ProximaNovaXbold.svg#ProximaNovaXbold") format("svg");
  font-weight: 900;
}

@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/proxima-nova-light.woff2") format("woff2"),
    url("../fonts/proxima-nova-light.woff") format("woff");
  font-weight: 200;
}

@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/proxima-nova-light.woff2") format("woff2"),
    url("../fonts/proxima-nova-light.woff") format("woff");
  font-weight: lighter;
}

body {
  font-family: "ProximaNova";
  font-size: 16px;
}

@keyframes moving {
  0% {
    top: 10%;
    margin: 30px;
  }

  25% {
    top: 12%;
    margin: 40px;
  }

  50% {
    top: 14%;
    margin: 50px;
  }

  75% {
    top: 12%;
    margin: 40px;
  }

  100% {
    top: 10%;
    margin: 30px;
  }
}

@keyframes movingtwo {
  0% {
    left: -200px;
  }

  25% {
    bottom: 70px;
  }

  50% {
    left: -170px;
  }

  75% {
    bottom: 55px;
  }

  100% {
    left: -180px;
  }
}

img {
  max-width: 100%;
}

:root {
  --defaultWhite: #fff;
  --defaultBlack: #000;
  --defaultGray: #c4c4c4;
  --skyBlue: #46badf;
  --appDarkYellow:rgb(210 163 34 / 60%);
  --appYellow:#ecb213;
  --appRed:#f44336;
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
  background-color: #f9f9f9 !important;
}

.btn:focus {
  box-shadow: none;
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle,
.btn-primary:hover,
.btn-check:focus+.btn-primary,
.btn-primary:focus {
  background-color: #46badf;
  border-color: #46badf;
  box-shadow: none;
}

.Mobile_toggle {
  display: none;
}

.container-fluid {
  max-width: 1745px;
  margin: auto;
  padding: 0 15px;
}

header {
  padding: 20px 0;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}

.container {
  padding: 0 15px;
}

.header_contant {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.header_list {
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.header_droap_box .btn {
  background: transparent;
  border: none;
  padding: 10px 15px;
  text-align: center;
}

.header_search_form {
  border: 1px solid #cccccc;
  border-radius: 100px;
  padding: 0 15px 0 15px;
  height: 60px;
  max-width: 38%;
  flex: 0 0 38%;
}

.form-select {
  position: relative;
}

.form-select {
  background-image: url("../images/cicon.png");
  background-position: right center;
  padding-right: 16px;
  background-size: 10px;
  background-position: right 15px top 20px;
}

.header_search_form .form-select {
  background-image: url("../images/location.svg");
  max-width: 120px;
  border: none;
  background-color: transparent;
  border-right: 1px solid #ccc;
  border-radius: 0;
  height: 100%;
  padding: 0 10px 0 30px;
  background-position: left;
  background-size: 20px;
}

.header_search_form .form-control {
  height: 100%;
  border-radius: 0;
  border: transparent;
  background: transparent;
  border-left: 1px solid #cccccc !important;
}

.select_market {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.select_market .form-select {
  border: none;
  background-color: transparent;
  background-position: right;
}

.nav-brand {
  margin-right: 32px;
}

.header_list.header_droap_box {
  padding: 0 0px;
  margin-left: auto;
}

.theme_btn {
  background: #33a7cc;
  border-color: #33a7cc;
  padding: 7px 15px;
  height: 40px;
  color: #fff;
  border-radius: 50px;
  min-width:90px;
  /* display: flex;
    align-items: center; */
}

.header_droap_box .btn:after {
  display: none;
}

.header_search_form .btn.btn_search {
  padding: 0 10px 0 0;
}

.header_search_form .btn.btn_search {
  padding: 0 10px 0 0;
}

.outline_btn {
  text-decoration: none;
  height: 40px;
  padding: 7px 24px;
  border: 1px solid transparent;
  border-radius: 50px;
  background: #fff;
  color: #000;
}

.outline_btn:hover {
  border: 1px solid #33a7cc;
  color: #33a7cc;
  box-shadow: 0 3px 6px rgb(0 0 0 / 7%);
}

.header_list.languge {
  padding: 0;
  max-width: 80px;
  flex: 0 0 80px;
}

.formsticky {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 0 0 27.5%;
  max-width: 27.5%;
  justify-content: flex-end;
}

.header_list.languge .form-select {
  background-color: transparent;
  border: none;
}

select:focus {
  outline: none;
  box-shadow: none;
}

.form-select:focus,
.form-control:focus {
  box-shadow: none;
}

.header_list.subscription {
  padding: 0 20px;
}

.banner_capion {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 560px;
  color: #fff;
}

.home_banner {
  position: relative;
}

.home_banner figure img {
  width: 100%;
}

.banner_capion .btn {
  color: #fff;
  min-width: 106px;
}

.banner_capion p {
  font-size: 16px;
  line-height: 22px;
  padding: 5px 0;
}

.banner_capion h1 {
  font-size: 46px;
  font-weight: bold;
}

figure {
  margin: 0;
}

.main_contant {
  padding: 50px 0;
}

.info_message {
  display: inline-block;
  background-color: #fffbef;
  border: 1px solid #ecb213;
  padding: 8px 36px;
  border-radius: 8px;
  color: #ecb213;
}

.main_contant .nav.nav-tabs {
  padding: 17px 32px 32px 32px;
  justify-content: center;
  border: none;
  background: #f7f8fa;
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 72px;
}

.product_head {
  text-align: center;
}

.tabs_outer .nav-item {
  width: 33.33%;
  height: auto;
  /* margin: 0 15px 15px 15px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 8px;
  /* box-shadow: 0 0 10px rgb(84 84 84 / 10%); */
  position: relative;
}

.tabs_outer .nav-item .nav-link>span {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}

.home_tab_checkbox input {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 99;
}

.checkbox_home_tab {
  width: 20px;
  height: 20px;
  border: 1.5px solid #dfdfdf;
  border-radius: 3px;
  background: #fff;
  left: auo;
  left: auto;
  right: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.tabs_outer .nav-tabs .nav-link.active .checkbox_home_tab {
  border-color: #33a7cc;
}

.tabs_outer .nav-tabs .nav-link.active .checkbox_home_tab:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 11px;
  background: #33a7cc;
  left: 10px;
  top: 3px;
  transform: rotate(45deg);
}

.tabs_outer .nav-item .active {
  border: 3px solid #33a7cc !important;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}

.tabs_outer .nav-tabs .nav-link.active.home_tab_checkbox input:checked {
  border: 3px solid #000;
}

.tabs_outer .nav-tabs .nav-link.active .checkbox_home_tab:after {
  content: "";
  position: absolute;
  width: 6px;
  height: 2px;
  background: #33a7cc;
  left: 3px;
  top: 9px;
  transform: rotate(45deg);
}

.tabs_outer .nav-tabs .nav-link.active {
  font-weight: 800;
}

.tabs_outer .nav-item .nav-link>span img {
  margin: 0;
  margin-right: 20px;
}

.tabs_outer .nav-item .nav-link {
  font-size: 18px;
  color: #000;
  width: 100%;
  /* height: 100%; */
  border: transparent;
  position: relative;
  z-index: 2;
}

.tabs_outer .nav-item .nav-link:after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 100%;
  z-index: 1;
  border: 3px solid transparent;
}

.product_card {
  position: relative;
  border-radius: 18px;
  overflow: hidden;
  margin: 0 5px 50px 5px;
  max-height: 280px;
  text-align: center;
}

.overlay_top {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  top: 6px;
  padding: 12px 21px 0 12px;
}

.boost_badge {
  background: rgb(51 167 204 / 60%);
  font-size: 13px;
  padding: 5px 7px 1px 8px;
  box-sizing: border-box;
  color: #fff;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: bold;
}

.overlay_bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(1deg, #00000085, transparent);
  padding: 50px 10px 15px 20px;
}

.overlay_bottom h4 a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  line-height: 1.3;
}

.product_info {
  display: flex;
  justify-content: space-between;
  padding: 0 13px 0 0;
}

.product_info .price {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.overlay_bottom h4 {
  margin-bottom: 2px;
  font-weight: 400;
}

.row>* {
  padding: 0 15px;
}

.row {
  margin: 0 -15px;
}

.product_card:hover figure img {
  transform: scale(1.2);
}

.product_card figure img {
  cursor: pointer;
  width: 100%;
  height: 280px;
  object-fit: cover;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.tabs_outer .nav-item .nav-link span {
  display: flex;
  /* align-items: center;
    justify-content: center;
    width: 100%; */
}

/* .tabs_outer .nav-item .nav-link span img {
    margin-bottom: 15px;
} */
.tabs_outer .nav-tabs .nav-link {
  border: none;
  border-radius: 10px;
  border: 1px solid #b4b4b4;
  padding: 17px 30px;
  background: #fff;
}

.tabs-outer li.nav-item {
  border: 1px solid #b4b4b4;
  margin: 5px 15px;
}

/* .nav-tabs .nav-link{
    border: 1px solid #000!important;
} */
.tabs-outer li.nav-item label {
  display: flex;
}

.tabs_outer .checkbox_tab .checkbox_design {
  right: 0;
  left: auto;
}

/* .tabs_outer .nav-item .nav-link.active span img {
    filter: brightness(100);
} */

.sold_badge {
  background: rgb(210 163 32 / 60%);
  font-size: 13px;
  padding: 4px 7px 2px 8px;
  box-sizing: border-box;
  color: #fff;
  border-radius: 3px;
  min-height: 22px;
  text-transform: uppercase;
  font-weight: bold;
}

.overlay_top span.heart {
  position: absolute;
  right: 20px;
}

.tabs_outer .outline-btn.theme_btn.btn {
  width: 240px;
  height: 60px;
  font-size: 20px;
  margin: 36px auto;
  display: block;
  background: #fff;
  color: #33a7cc;
  font-weight: 500;
}

.mobile_app {
  background-image: url("../images/app-bg.png");
  background-color: #33a7cc;
  padding: 0px 0;
  color: #fff;
  background-repeat: no-repeat;
  background-position: 38%;
  margin-top: 50px;
}

.footer_block_right_card {
  margin-right: 100px;
}

.app_info {
  max-width: 320px;
  padding: 50px 0;
}

.app_info h3 {
  font-weight: bold;
  margin: 0;
}

.app_info p {
  font-weight: bold;
  line-height: 19px;
  padding: 0;
}

.app_detail h5 {
  font-weight: bold;
}

.installing_link {
  display: flex;
  align-items: center;
}

.installing_link a {
  margin-right: 12px;
}

.app_detail {
  padding: 24px 0 0 0;
}

.app_info_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img_app_fream {
  margin: -80px 0;
}

.footer {
  background: #101013;
  padding: 50px 0;
  position: relative;
}

.footer a {
  color: #aca9b4;
  text-decoration: none;
}

.footer_nav_block h3 {
  color: #fff;
  font-size: 16px;
}

.footer_nav_block li {
  list-style: none;
}

.footer_nav_block ul {
  padding: 0;
}

.footer_link a {
  display: block;
  font-weight: lighter;
  padding: 2px 0;
}

.socialfooter {
  display: flex;
}

.footer_nav_block {
  flex: 0 0 23.333%;
  max-width: 23.333%;
  padding: 0 15px;
}

.footer_block p {
  color: #fff;
}

.footer_about {
  color: #aca9b4;
  padding: 20px 10px 0 0px;
  line-height: 20px;
}

.bottom_footer { 
  background: #fff;
  border-top: 1px solid #292929;
  padding: 24px 0;
}

.bottom_footer_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer_social a {
  margin: 0 5px;
}

.footer_copyright {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}

.footer_copyright a {
  color: #787878;
  text-decoration: none;
}

.newsletter_form {
  display: flex;
  background: #2d2d2d;
  border-radius: 50px;
  height: 38px;
}

.newsletter_form .form-control:focus {
  background-color: transparent;
}

.newsletter_form input {
  background: transparent;
  border: transparent;
  font-size: 14px;
  color: #fff !important;
}

.processing_steps ul li.active figure {
  border: 2px solid #33a7cc;
}

.newsletter_form .btn {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background: #33a7cc;
  border-radius: 50px;
}

.footer_nav_block.newslatter {
  flex: 0 0 30%;
  max-width: 30%;
}

.footer_logo img {
  filter: brightness(100);
}

.left_sticky ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border: none;
}

.left_sticky {
  position: fixed;
  background: #fff;
  width: 86px;
  left: 0;
  top: 50%;
  border-radius: 0 15px 15px 0;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  overflow: hidden;
  transform: translateY(-50%);
  z-index: 9;
}

.left_sticky a {
  color: #b2b2b2;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 15px;
  border-bottom: 1px solid #dcdcdc;
}

.left_sticky a img {
  display: block;
}

.left_sticky a img {
  margin: auto;
  margin-bottom: 2px;
}

.left_sticky a:hover {
  background: #33a7cc;
  color: #fff;
}

.left_sticky a span {
  padding: 6px 0 0 0;
  display: block;
}

.left_sticky a:hover img {
  filter: brightness(100);
}

.left_sticky li:last-child a {
  border: none;
}

.notification.dropdown .btn {
  position: relative;
}

.notification.dropdown .btn:before {
  width: 7px;
  height: 7px;
  content: "";
  position: absolute;
  background: red;
  border-radius: 50%;
  border: 1px solid #fff;
  right: 17px;
}

.showMobileNav .formsticky {
  transform: translateX(0px);
}

/* header-animation */

header.fixed-top {
  width: 100%;
  position: fixed;
  z-index: 9;
  top: 0%;
  -webkit-animation: slide-down 0.7s;
  animation: slide-down 0.7s;
  -webkit-box-shadow: 0px 0.071rem 0.71rem 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0.071rem 0.71rem 0px rgba(0, 0, 0, 0.2);
  background: #fff;
}

@keyframes slide-down {
  0% {
    opacity: 1;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/* header-animation */

.header_search_form .form-control {
  max-width: calc(100% - 154px);
}

.header_contant {
  justify-content: center;
}

.showmobilenav {
  transform: translate(0);
  flex: 0 0 100%;
  max-width: 250px;
}

.showmobilenav {
  transform: translate(0) !important;
  flex: 0 0 100%;
  max-width: 250px;
}

.Mobile_toggle:hover,
.Mobile_toggle:focus {
  background: transparent;
  box-shadow: none;
  outline: none;
  padding: 5px;
}

/**================login==========**/

.subscription_modal .modal-dialog {
  max-width: 1280px;
  border-radius: 16px;
  overflow: hidden;
}

.crose_btn {
  position: absolute;
  right: 0;
  background: transparent;
  border: none;
  margin: 20px;
  z-index: 9;
}

.subscription {
  display: flex;
}

.popup_left {
  flex: 0 0 40%;
  max-width: 40%;
  padding: 64px 28px;
  text-align: center;
  background: #f7f8fa;
}

.subscription_modal .modal-footer {
  padding: 0;
  border: none;
}

.subscription_modal .modal-body {
  padding: 0;
}

.popup_left h5 {
  font-size: 32px;
  line-height: 1.3;
  padding: 0 15px;
  margin-bottom: 20px;
  font-weight: normal;
}

.right_form_area {
  flex: 0 0 60%;
  max-width: 60%;
  padding: 64px 150px;
}

.right_form_area h4 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #000000;
}

.login_with {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px -10px;
}

.login_with a {
  padding: 0 10px;
}

.hr_border {
  text-align: center;
  position: relative;
}

.hr_border:after {
  content: "";
  height: 1px;
  position: absolute;
  width: 100%;
  background: #e2e2e2;
  left: 0;
  right: 0;
  top: 13px;
  z-index: 1;
}

.hr_border span {
  background: #fff;
  padding: 0 10px;
  position: relative;
  z-index: 2;
  font-size: 15px;
  color: #aca9b4;
}

.login_form {
  margin: 24px 0 15px 0;
}

.login_form .form-label {
  font-size: 14px;
  color: #000;
  margin-bottom: 7px;
}

.login_form .form-control {
  height: 45px;
  border: 1px solid #e2e2e2;
  font-size: 14px;
  color: #aca9b4;
  border-radius: 8px;
}

.text_view {
  position: absolute;
  font-size: 14px;
  right: 20px;
  bottom: 12px;
  color: #ecb213;
  font-weight: 100;
  cursor: pointer;
}

.text-danger {
  color: #ff0000 !important;
}

.login_form .mb-3 {
  position: relative;
}

.remamber {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin: 0 0 20px 0;
}

.forgot {
  text-decoration: none;
  font-size: 12px;
  color: #22b81d;
}

.remamber label {
  display: flex;
  align-items: center;
}

.checkbox_tab .checkbox_design {
  background: #fff;
  border: 1px solid #cdcdcd;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  position: absolute;
  left: 0;
}

.checkbox_tab {
  position: relative;
  padding-left: 25px;
}

.checkbox_tab input {
  position: absolute;
  left: 0;
  z-index: 2;
  width: 20px;
  height: 20px;
  opacity: 0;
}

.checkbox_tab input:checked+.checkbox_design {
  border: 1px solid #33a7cc;
}

.checkbox_tab input:checked+.checkbox_design:after {
  content: "";
  position: absolute;
  width: 6px;
  height: 2px;
  background: #33a7cc;
  left: 3px;
  top: 9px;
  transform: rotate(45deg);
}

.checkbox_tab input:checked+.checkbox_design:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 11px;
  background: #33a7cc;
  left: 10px;
  top: 3px;
  transform: rotate(45deg);
}

.login_form .theme_btn {
  width: 100%;
  border-radius: 8px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  margin: 24px 0 0 0;
}

.new_user {
  font-size: 14px;
  color: #000;
}

.new_user a {
  color: #22b81d;
  font-weight: bold;
  text-decoration: none;
}

.popup_left figure {
  margin-top: 132px;
}

.subscription_modal {
  padding: 0 15px !important;
}

.remamber label a {
  color: #22b81d;
  text-decoration: none;
}

.breadcrumb {
  margin: 0;
  padding: 15px 0;
}

.breadcrumb ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 -5px;
  flex-wrap: wrap;
}

.breadcrumb ul li {
  padding: 0 3px;
  font-size: 14px;
  color: #979b9f;
}

.breadcrumb ul li i {
  font-size: 10px;
  margin-left: 6px;
}

.breadcrumb ul li a {
  color: #000;
  text-decoration: none;
}

.outline_wrapper {
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  padding: 24px 30px 0 30px;
  margin-bottom: 60px;
}

.outline_wrapper h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.product_item_horizantal {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.product_item_horizantal figure {
  width: 100px;
}

.offer_product_detail {
  padding-left: 10px;
}

.offer_product_detail h3 {
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
}

.offer_product_detail small {
  display: block;
  color: #000;
  font-size: 16px;
}

.offer_product_detail .price {
  color: #111113;
  font-size: 24px;
  font-weight: bold;
}

.offer_product_detail .off_price {
  padding-left: 10px;
  font-size: 16px;
  font-weight: normal;
  color: #ff0000;
  text-decoration: line-through;
}

.wrapper_form {
  padding-top: 20px;
  margin-top: 10px;
}

.best_offers_detail {
  border-top: 2px dashed #e2e2e2;
  padding-top: 24px;
}

.matherd_select h4 {
  margin: 0;
  padding-right: 10px;
  font-size: 16px;
  color: #111113;
  font-weight: normal;
}

.matherd_select {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.matherd_select .radio_btn {
  padding: 0 10px 0 32px;
  display: flex;
  align-items: center;
}

.radio_btn {
  position: relative;
}

.custome_radio {
  border: 1px solid #ddd;
  width: 22px;
  height: 22px;
  border-radius: 50px;
  position: absolute;
  left: 6px;
  z-index: 1;
}

.radio_btn input[type="radio"] {
  position: absolute;
  left: 5px;
  width: 22px;
  height: 22px;
  z-index: 2;
  opacity: 0;
}

.radio_btn input[type="radio"]:checked+.custome_radio {
  border-color: #33a7cc;
}

.custome_radio:after {
  content: "";
  background: #fff;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 100px;
  margin: 4px;
}

.radio_btn input[type="radio"]:checked+.custome_radio:after {
  background: #33a7cc;
}

.matherd_select .radio_btn {
  margin-right: 20px;
}

.make_offer_form {
  padding-top: 20px;
}

.make_offer_form ul {
  padding: 12px 0 20px 0;
  list-style: none;
  display: flex;
  align-items: flex-start;
  margin: 0px -10px;
}

.make_offer_form ul li:not(.custome_price) {
  margin: 0 10px;
  border: 1px solid #dfdfdf;
  padding: 8px 32px 5px 10px;
  border-radius: 10px;
  position: relative;
  font-size: 18px;
  flex: 0 0 170px;
  max-width: 170px;
  color: #aca9b4;
}

.make_offer_form ul li:not(.custome_price) .checkbox_design {
  position: absolute;
  right: 10px;
  left: auto;
  top: 18px;
}

.make_offer_form ul li:not(.custome_price) .checkbox_tab.bluetab {
  padding: 0;
  position: initial;
}

.make_offer_form ul li:not(.custome_price) .checkbox_tab.bluetab input {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
}

.make_offer_form ul li small {
  display: block;
  font-size: 13px;
  line-height: 1;
}

.custome_price {
  padding-left: 15px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

.custome_price label {
  margin: 0;
}

.make_offer_form ul li input:checked~span,
.make_offer_form ul li input:checked~small {
  color: #000;
}

.genrating_price {
  float: left;
}

.genrating_price strong {
  font-size: 32px;
  display: block;
  color: #33a7cc;
  line-height: 1.1;
  margin-right: 32px;
  padding-right: 10px;
  padding-bottom: 24px;
}

.genrating_price strong small {
  display: block;
  font-size: 14px;
  font-weight: normal;
  color: #aca9b4;
}

.make_offer_form .theme_btn.next {
  border-radius: 6px;
  border: 1px solid transparent;
  color: #fff;
  min-width: 140px;
  height: 40px;
}

.order_detail_box {
  border: 1px solid #e2e2e2;
  padding: 15px 20px;
  border-radius: 8px;
}

.order_detail_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px dashed #e2e2e2;
  margin-bottom: 10px;
}

.order_detail_head h4 {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
}

.order_detail_head .edit,
.delete,
.money_add .edit {
  background: no-repeat;
  border: none;
  color: #33a7cc;
  font-size: 16px;
}

.delete {
  margin-left: 5px;
}

.order_detail_head .edit .fa-pen,
.money_add .edit .fa-pen {
  margin-right: 5px;
  font-size: 14px;
}

.order_caption {
  padding: 5px 0 0 0;
  min-height: 90px;
}

.order_caption strong {
  font-weight: bold;
  color: #111113;
}

.order_caption p {
  font-size: 16px;
  line-height: 1.4;
  padding: 5px 0;
  margin: 0;
}

.order_detail_box {
  margin-bottom: 24px;
}

.outline_wrapper .col-sm-6 {
  padding: 0 15px;
}

.outline_wrapper .row {
  margin: 0 -10px;
}

.order_caption ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.order_caption ul li {
  color: #33a7cc;
}

.order_caption ul li img {
  margin-right: 10px;
}

.listing_condition {
  padding: 15px 0;
}

.listing_condition ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 13px 30px;
}

.listing_condition li {
  color: #aca9b4;
}

.listing_condition li span {
  color: #000;
  padding-left: 5px;
}

.description {
  padding: 15px 0;
}

.seller_titles {
  font-size: 18px;
  color: #000;
  margin: 0 0 3px 0;
}

.description p {
  font-size: 16px;
  line-height: 1.4;
}

.order_summary_sidebar h3 {
  background: #f7f8fa;
  font-size: 18px;
  padding: 15px;
  margin: 0;
}

.order_summary_sidebar h3 small {
  color: #aca9b4;
  font-size: 14px;
  font-weight: 100;
  margin-top: 4px;
}

.order_summary_sidebar {
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  overflow: hidden;
}

.order_summary_list {
  margin: 0;
  padding: 15px;
  list-style: none;
}

.order_summary_list li {
  display: flex;
  justify-content: space-between;
  line-height: 1;
  padding: 5px 0;
  color: #000;
}

.order_summary_list li strong {
  text-align: right;
  font-size: 14px;
}

.final_price {
  padding: 15px;
  border-top: 1px solid #dedede;
}

.final_costing_head {
  display: flex;
  font-weight: bold;
  font-size: 18px;
  flex-direction: column;
}

.listing_condition .description p {
  font-size: 16px;
  color: #111113;
}

.final_costing_head .price {
  color: #33a7cc;
  font-size: 15px;
}

.description p {
  font-size: 14px;
  line-height: 1.5;
  color: #aca9b4;
  padding: 5px 0 0 0;
  margin: 0;
}

.description a {
  color: #33a7cc;
  font-size: 16px;
  text-decoration: none;
  padding: 5px 0;
  display: block;
}

.order_summary_sidebar .btn {
  margin: 0 15px 36px;
  width: calc(100% - 30px);
  color: #fff;
  border-radius: 5px;
  border: none;
  justify-content: center;
}

.pay-advt {
  background-color: #f1f5fb;
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
}

.pay-advt .checkbox_tab .checkbox_design,
.pay-advt .checkbox_tab input {
  top: -9px;
}

.pay-advt strong {
  font-size: 15px;
}

.pay-advt span img:not(.checkbox_tab img) {
  margin: 0 10px;
}

.pay-advt .nav_dots {
  position: absolute;
  right: 15px;
  background-color: transparent;
  border: transparent;
}

.pay-advt {
  position: relative;
  height: 64px;
}

.pay-advt .add_bank-detail {
  background: transparent;
  border: none;
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: bold;
  color: #33a7cc;
}

.creadit_cards {
  padding: 24px 0;
}

.pay-advt strong small {
  display: block;
  font-size: 12px;
  color: #545454;
  font-weight: normal;
}

.pay-advt strong {
  line-height: 1.3;
}

.btn.btn-primary.add-new-card {
  border-radius: 10px;
  margin: 32px 0 0 0;
  height: 50px;
  font-size: 18px;
  width: calc(25% - 15px);
}

.pay-advt.white_bg {
  border: 1px solid #e2e2e2;
  background-color: #fff;
}

.success_popup figure {
  display: block;
  text-align: center;
}

.success_popup {
  text-align: center;
}

/* .success_popup small {
    display: block;
    color: #00C936;
    font-size: 18px;
} */

/* .success_popup h3 {
    margin: 0;
    font-size: 32px;
    font-weight: 900;
    color: #000;
} */

.success_popup .theme_btn {
  margin: 15px 0 0 0;
  border-radius: 5px;
  border: none;
  color: #fff;
  padding: 7px 24px;
  font-size: 18px;
}

.success_popup figure {
  display: block;
  text-align: center;
}

.success_popup {
  text-align: center;
}

.success_popup small {
  display: block;
  color: #aca9b4;
  font-size: 20px;
  margin-bottom: 10px;
}

.success_popup h3 {
  margin: 0;
  font-size: 32px;
  font-weight: 900;
  color: #000;
}

.success_popup h3 {
  color: #00c936;
}

.success_popup .theme_btn {
  margin: 15px 0 0 0;
  border-radius: 5px;
  border: none;
  color: #fff;
  padding: 7px 24px;
  font-size: 18px;
  margin: 0 auto;
}

.success_modal .modal-dialog {
  max-width: 510px;
}

.crose_btn {
  border: 1px solid #ddd;
  border-radius: 50px;
  width: 24px;
  height: 24px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 16px 16px 0 0; */
}

.crose_btn img {
  max-width: 10px;
}

.success_modal .modal-dialog .modal-content {
  border-radius: 16px;
  padding: 24px;
}

.pay-advt .dropdown {
  position: absolute;
  right: 0;
  top: 0;
}

.pay-advt .nav_dots {
  width: 24px;
  padding: 5px 10px;
  box-sizing: border-box;
  right: 10px;
  top: 13px;
  box-shadow: none;
}

.btn-check:active+.btn-success,
.btn-check:checked+.btn-success,
.btn-success.active,
.btn-success:active,
.show>.btn-success.dropdown-toggle {
  background: transparent;
  color: #000;
  outline: none;
  box-shadow: none !important;
}

.pay-advt .nav_dots:after {
  display: none;
}

.boost_duration .nav_dots:after {
  display: none;
}

.boost_duration .nav_dots {
  background: transparent;
  border: none;
}

.boost_duration .nav_dots:focus {
  box-shadow: none;
}

.pay-advt .dropdown-menu,
.boost_duration .dropdown-menu {
  background: #f1f5fb;
  min-width: 90px;
  padding: 0;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 3px;
  border-color: #e0e9f6;
}

/* .dropdown-toggle:after{
    display: none!important;
}
.boost_duration .dropdown-menu:focus{
    box-shadow: transparent;
}
.btn-success{
    background: transparent;
    border: none;
    padding: 0px;
}
.boost_duration .dropdown-menu{
    border:none;
    background: transparent!important;
} */
.pay-advt .dropdown-menu .dropdown-item {
  border-bottom: 1px solid #e0e9f6;
  padding: 5px 15px;
  font-size: 12px;
}

.pay-advt .dropdown-menu .dropdown-item:last-child {
  border: none;
}

.add_new_card h4 {
  color: #111113;
  font-size: 15px;
  margin: 10px 0;
}

.add_new_card input {
  height: 45px;
  font-size: 14px;
  border-radius: 8px;
}

.add_new_card input:focus {
  border-color: #000;
}

.add_new_card .theme_btn {
  font-size: 18px;
  width: 100%;
  border-radius: 10px;
  height: 50px;
  margin: 15px 0 0 0;
}

.add_card .modal-body {
  padding: 40px 24px 54px 24px;
}

.processing_steps ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  text-align: center;
  position: relative;
  z-index: 2;
  padding: 0;
  margin: 0;
}

.processing_steps ul li figure {
  border: 2px solid #e2e2e2;
  width: 84px;
  height: 84px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: #fff;
}

.processing_steps ul li span {
  font-size: 16px;
  color: #aca9b4;
  padding: 5px 0;
  display: block;
}

.processing_steps ul li.active span {
  color: #111113;
}

.processing_steps {
  padding: 20px 50px 25px 50px;
  margin-bottom: 0px;
  position: relative;
  margin-top: 29px;
}

.processing_steps:after {
  content: "";
  height: 2px;
  background: #e2e2e2;
  position: absolute;
  left: 0;
  right: 0;
  top: 62px;
  z-index: 1;
  width: calc(100% - 120px);
  margin: auto;
}

.tracking_head {
  display: flex;
  justify-content: space-between;
  background: #047fa7;
  padding: 20px 30px;
  color: #fff;
  margin: -24px -30px 0px -30px;
  border-radius: 8px 8px 0 0;
}

.tracking_head h2 {
  margin: 0;
}

.list_outer ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  justify-content: space-between;
}

.list_outer ul li {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  padding: 8px 0px;
  font-size: 16px;
  color: #aca9b4;
}

.list_outer ul li span {
  color: #000;
}

.list_outer {
  margin: 0px -30px 0px -30px;
  padding: 15px 30px;
}

.pay-advt {
  margin-bottom: 15px;
}

.after_login .user_name {
  max-width: 134px;
  padding: 0 10px;
  white-space: nowrap;
  margin-right: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 0 0 100%;
  max-width: calc(100% - 24px);
}

.after_login {
  display: flex;
  align-items: center;
  padding: 0 15px;
}

/* 22/10/21 */

.middle_banner_sec {
  background-image: url(../images/middle_background.jpg);
  background-repeat: no-repeat;
  position: relative;
  padding-top: 17px;
  background-size: cover;
  background-position: right;
  height: 264px;
}

/* .middle_banner_sec .camera_icon_banner {
    position: relative;
} */
.camera_icon_banner input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  z-index: 99;
}

.middle_banner_sec .camera_icon_banner {
  position: absolute;
  right: 6%;
  top: 14%;
}

.middle_banner_sec .middle_img {
  text-align: right;
}

.sidebar_sie_menu:last-child {
  border-bottom: none;
}

.sidebar {
  width: 302px;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  background: #fff;
  flex: 0 0 302px;
  overflow-y: auto;
}

.sidebar_main {
  margin-top: -21%;
  position: relative;
}

.sidebar_notify .email_notify {
  padding: 10px 19px 10px 48px;
  justify-content: space-between;
  display: flex;
  width: 100%;
}

.profile_detail.edit_Pic figure {
  position: relative;
  display: flex;
  overflow: visible;
  border: 2px solid #fff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}

.profile_detail figure {
  width: 120px;
  height: 120px;
  margin: auto;
  border-radius: 100px;
}

.profile_detail.edit_Pic img {
  border-radius: 100px;
}

.profile_detail.edit_Pic figure input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  z-index: 99;
}

.profile_detail.edit_Pic figure .camera_icon {
  position: absolute;
  right: 0px;
  object-fit: fill;
  width: 32px;
  height: 32px;
  right: auto;
  left: 50%;
  transform: translateX(-50%);
  bottom: -17px;
}

.modal-backdrop.show {
  opacity: 0.7;
}

.sidebar .user_rating span i {
  color: #aca9b4;
}

.user_rating small {
  color: #aca9b4;
  font-size: 11px;
  display: inline-block;
  margin-left: 6px;
}

.user_rating span i {
  font-size: 12px;
  margin: 1px;
  color: #aca9b4;
}

.user_rating .active_icon {
  color: #ffbc01 !important;
}

.sidebar .profile_detail {
  border-bottom: 1px solid #e2e2e2;
  padding: 30px 0 20px 0;
}

.rating_star {
  margin-top: 19px;
}

.rating_star h2 {
  font-size: 24px;
  margin-bottom: 0px;
}

.manage_address {
  padding: 40px 0px;
}

.sidebar_sie_menu ul li {
  list-style-type: none;
}

.sidebar_sie_menu ul li a {
  text-decoration: none;
  font-size: 14px;
  color: #000;
  padding: 10px 19px 10px 48px;
  display: inline-block;
  width: 100%;
}

.sidebar_sie_menu ul li a {
  text-decoration: none;
  font-size: 14px;
  color: #000;
}

.sidebar_sie_menu h5 {
  font-size: 16px;
  margin-bottom: 16px;
  padding: 0px 19px;
}

.sidebar_sie_menu ul {
  padding: 0;
  margin: 0;
}

.sidebar_sie_menu ul li a:hover {
  color: #33a7cc;
}

.switch_btn input {
  width: 40px !important;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 3 !important;
  height: 24px !important;
  top: -3px !important;
  opacity: 0 !important;
}

.sidebar_sie_menu ul li span {
  font-size: 14px;
  color: #33a7cc;
  font-weight: 600;
  float: right;
}

.sidebar_sie_menu ul li.active a {
  color: #33a7cc;
}

.sidebar_sie_menu ul li.active {
  background: #fcfcfc;
}

.sidebar_sie_menu h5 span {
  display: inline-block;
  margin-right: 9px;
}

.sidebar_sie_menu {
  border-bottom: 1px solid #dedede;
  padding: 24px 0px 13px 0px;
}

/* toggle */

input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.dashboard_main .outline_wrapper .dropdown-toggle {
  background: transparent;
  border: none;
  padding: 0px;
  text-align: right;
  display: block;
}

.dashboard_main .order_detail_box .order_caption p {
  color: #545454;
}

.dashboard_main .order_detail_head span {
  background: #e4c879;
  color: #fff;
  border-radius: 3px;
  padding: 3px 11px;
  font-size: 12px;
  font-weight: 600;
}

.add_new_btn .btn {
  border-radius: 10px;
}

.dashboard_main .outline_wrapper .dropdown-toggle:focus {
  box-shadow: none;
  border: none;
}

.dashboard_main .dropdown-toggle::after {
  display: none;
}

.manage_address .sidebar_outer {
  display: flex;
}

.sidebar_outer .dashboard_main {
  width: calc(100% - 302px);
  margin-left: 29px;
}

.nlabel {
  cursor: pointer;
  text-indent: -9999px;
  width: 41px;
  height: 25px;
  background: rgba(172, 169, 180, 0.53);
  display: block;
  position: relative;
  margin-left: 11px;
  border-radius: 13px;
}

input:checked+.nlabel {
  background: rgba(51, 167, 204, 0.53);
}

.nlabel:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 3px;
  width: 21px;
  height: 20px;
  background: #aca9b4;
  border-radius: 100%;
  transition: 0.3s;
  border: 2px solid #fff;
}

input:checked+.nlabel:after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
  background: #33a7cc;
}

/* language_label */

/* .sidebar_sie_menu .language_label{
    cursor: pointer;
    text-indent: -9999px;
    width: 41px;
    height: 25px;
    background: rgba(172, 169, 180 , .53);
    display: block;
    position: relative;
    border-radius: 13px;
}
.sidebar_sie_menu input:checked + .language_label {
    background: rgba(51, 167, 204 , .53);
}
.sidebar_sie_menu .language_label:after {
    content: '';
    position: absolute;
    top: 2px;
    left:3px;
    width: 21px;
    height: 20px;
    background: #ACA9B4;
    border-radius: 100%;
    transition: 0.3s;
    border: 2px solid #fff;
}
.sidebar_sie_menu input:checked + .language_label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
    background: #33A7CC;
} */

.sidebar_sie_menu input[type="radio"] {
  opacity: 0;
  position: absolute;
  right: 23px;
}

.sidebar_sie_menu input[type="radio"]+label {
  color: #000;
  cursor: pointer;
}

.sidebar_sie_menu input[type="radio"]+label span {
  display: inline-block;
  /* width:30px;
    height:10px;
    margin:1px 0px 0 -30px; */
  cursor: pointer;
  border-radius: 20%;
}

.sidebar_sie_menu input[type="radio"]:checked+label span::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f00c";
  opacity: 1;
  position: absolute;
  right: 23px;
  color: #33a7cc;
}

/* language_label */

/* toggle */

/* my_account_sec */

.my_account_sec .myaccount_group {
  display: flex;
  width: 100%;
}

.account_step {
  margin-bottom: 20px;
}

.account_infor_verfiy ul {
  padding: 0px;
  display: flex;
  margin: 0px;
}

.account_infor_verfiy ul li {
  list-style-type: none;
  display: flex;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  padding: 9px 20px 9px 20px;
  flex: 0 0 45%;
  max-width: 45%;
  justify-content: space-between;
  color: #33a7cc;
}

.doffo_info {
  border-top: 1px dashed #e2e2e2;
  padding: 44px 0px;
}

.doffo_info h2 {
  font-size: 24px;
  font-weight: 600;
}

.doffo_info p {
  color: #aca9b4;
  font-size: 16px;
  line-height: 30px;
}

.account_infor_verfiy {
  margin-bottom: 60px;
}

.account_infor_verfiy ul li:last-child {
  margin-left: 21px;
}

.my_account_sec .myaccount_group .form_field {
  flex: 0 0 45%;
  max-width: 45%;
}

.account_step .order_detail_head .edit,
.delete {
  margin-left: 10px;
}

.account_step .last_name_user {
  margin-left: 21px;
}

.my_account_sec .account_step .order_detail_head {
  justify-content: initial;
  margin: 0;
}

.account_step .order_detail_head h4 {
  font-weight: bold;
  font-size: 18px;
}

.my_account_sec .form-control {
  border-color: #cccccc;
  border-radius: 8px;
  height: 45px;
  padding: 0px 20px;
  font-size: 14px;
  color: #111113;
}

.my_account_sec .form-control::placeholder {
  color: #000;
}

/* my_account_sec */

/* togggle_dashboard */

.togggle_dashboard {
  background: #33a7cc;
  position: fixed;
  z-index: 99;
  left: 10px;
  top: 50%;
  padding: 9px;
  width: 47px;
  height: 47px;
  text-align: center;
  color: #fff;
  box-shadow: 1px 2px 1px 1px #00000021;
  border-radius: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #fff;
}

.toggle_main {
  display: none;
}

.togggle_dashboard img {
  filter: brightness(0) invert(1);
}

/* togggle_dashboard */
.sidebar_overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  border: 0;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 9;
  transition: all 0.4s ease;
  visibility: hidden;
  background-color: #000;
}

/* 22/10/21 */

/* 28/10/21 */

.listing_brands_new {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0px -30px;
  padding: 18px 30px;
  background-color: #f7f8fa;
  border: 1px dashed #e2e2e2;
  border-left: none;
  border-right: none;
  margin-top: 30px;
}

.listing_brands_new .listing_brand_cat {
  max-width: 19.33%;
  flex: 0 0 19.33%;
}

.listing_brands_new .listing_brand_cat:first-child {
  max-width: 42%;
  flex: 0 0 42%;
}

.listing_brands_new .listing_brand_cat ul {
  padding: 0px;
  margin: 0px;
}

.listing_brands_new .listing_brand_cat ul li span {
  color: #000;
  padding-left: 5px;
}

.listing_brands_new .listing_brand_cat ul li {
  list-style-type: none;
  color: #aca9b4;
  line-height: 30px;
}

.add_card .modal-content {
  border-radius: 13px;
}

.order_payment_method span {
  display: inline-block;
  font-size: 16px;
  color: #545454;
  margin-bottom: 6px;
}

.order_payment_method small img {
  margin-right: 11px;
}

.confirm_order_info {
  border-top: 1px solid #e2e2e2;
  padding-top: 25px;
}

.tabs_outer .tab_outer_heading {
  background: #f7f8fa;
  padding: 26px 32px 0px 32px;
  border-radius: 8px 8px 0px 0px;
}

.tabs_outer .tab_outer_heading h2 {
  margin: 0;
  font-size: 32px;
  color: #000;
  font-weight: 100;
}

.tab_product_filter h2 {
  font-size: 32px;
  margin: 0;
}

.tab_product_filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

tab_product_filter .theme_btn {
  border-radius: 0px;
}

.tab_product_filter .theme_btn {
  border-radius: 4px;
  display: flex;
  align-items: center;
  background: #ecb213;
  border-color: #ecb213;
  position: relative;
  height: 44px;
  padding: 5px 21px;
}

.tab_product_filter .theme_btn img {
  margin-right: 14px;
}

.tabs_outer .outline-btn.theme_btn.btn:after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-left: 8px;
}

.home_price_filter form {
  display: flex;
  flex-wrap: wrap;
}

.newtoggle {
  visibility: visible !important;
  margin-top: 0px !important;
  opacity: 1 !important;
}

.home_price_filter {
  background: #f7f8fa;
  padding: 39px 20px 18px 20px;
  border: 1px dashed #f7f8fa;
  border-radius: 8px;
  margin-top: -120px;
  visibility: hidden;
  transition: 1s all ease;
  opacity: 0;
}

.tab_product_filter .theme_btn::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 13px solid #ecb213;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
}

.home_price_filter form .filter_dropdowns .filter_product {
  display: flex;
  align-items: center;
}

.form-label {
  margin-bottom: 0px;
  margin-right: 14px;
  font-size: 14px;
  color: #212121;
}

.home_price_filter {
  background: #f7f8fa;
  padding: 39px 20px 18px 20px;
  border: 1px dashed #f7f8fa;
  border-radius: 8px;
}

.home_price_filter form .sort_by {
  flex: 0 0 234px;
  max-width: 234px;
}

.filter_dropdowns {
  padding: 5px 9px;
}

.home_price_filter .price_level {
  flex: 0 0 280px;
  max-width: 280px;
}

.price_level .filter_product .form-select {
  margin-right: 8px;
}

.filter_dropdowns .form-select {
  flex: 1 1;
  font-size: 14px;
  color: #212121;
  height: 36px;
  padding: 5px 6px;
  border-radius: 4px;
  border-color: #e2e2e2;
  background-position: right 10px 14px;
  background-position: top 15px right 6px;
}

.home_price_filter .distance_level {
  flex: 0 0 423px;
  max-width: 423px;
}

.home_price_filter .filter_btn button {
  border-radius: 4px;
  font-size: 18px;
  height: 36px;
  padding: 0px 27px;
}

.home_price_filter .filter_btn {
  flex: 1;
  padding-left: 40px;
}

/* remain_balance */

.remain_balance {
  background: #f7f8fa;
  padding: 15px 30px 15px 30px;
  border: 1px solid #e9e9e9;
}

.remain_balance span {
  font-size: 20px;
  color: #111113;
  display: flex;
  align-items: center;
}

.remain_balance small {
  color: #33a7cc;
  font-size: 26px;
  font-weight: bold;
  margin-left: 15px;
}

.transcation_history_sec h2 {
  margin: 0;
  font-size: 20px;
}

.transcation_history_sec {
  display: flex;
  justify-content: space-between;
  margin: 35px 0px 21px 0px;
}

.transcation_history_sec span {
  font-size: 16px;
}

.trans_history_money ul li {
  list-style-type: none;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  padding: 9px 19px 9px 9px;
  align-items: center;
  margin-bottom: 14px;
}

.boost_duration {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #e2e2e2;
  margin-bottom: 12px;
  align-items: center;
}

.boost_duration span {
  font-size: 13px;
}

.manage_post_ul {
  display: flex;
  justify-content: space-between;
  flex: 1 1;
  align-items: center;
}

.main-heading h2 {
  font-size: 20px;
  margin: 0;
}

.main-heading span {
  display: block;
  font-size: 14px;
  color: #aca9b4;
  margin-left: 10px;
}

.main-heading {
  margin-bottom: 23px;
}

.danger {
  color: #ff3939;
}

.bg-success {
  background: #22b81d !important;
}

.bg-danger {
  background: #e60a0a !important;
}

.progress {
  background-color: #ecb213;
}

.success {
  color: #7db73e;
}

.onroute {
  background-color: #33a7cc;
}

.money_add .amount_price {
  font-size: 18px;
  font-weight: bold;
}

.trans_history_money ul {
  padding: 0;
  margin: 0;
}

.trans_history_money .money_add .trans_date {
  display: flex;
  align-items: center;
}

.trans_history_money .money_add .trans_date .trans_title h3 {
  font-size: 18px;
  margin: 0;
}

.trans_date_list span {
  font-size: 13px;
  display: block;
  line-height: 20px;
  text-align: center;
}

.trans_history_money .money_add .trans_date .trans_title {
  margin-left: 18px;
  padding-top: 11px;
}

.trans_history_money .money_add .trans_date .trans_title p {
  font-size: 13px;
}

.trans_date_list span strong {
  font-size: 26px;
  font-weight: bold;
  display: block;
  margin: 0;
}

.trans_history_money .trans_title span {
  color: #aca9b4;
  font-size: 12px;
  font-weight: normal;
  margin-right: 20px;
}

.trans_date_list {
  background: white;
  display: block;
  width: 67px;
  height: 67px;
  border-radius: 0px 6px 6px 0px;
  box-shadow: 0 1px 6px rgb(0 0 0 / 16%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 4px solid transparent;
}

.danger_border {
  border-color: #ff3939;
}

.success_border {
  border-color: #7db73e;
}

/* remain_balance */

.cashout_modal h4 {
  font-size: 28px;
  text-align: center;
}

.cashout_modal .cashout_balance {
  display: flex;
  justify-content: center;
  margin-bottom: 26px;
  align-items: center;
}

.cashout_modal .cashout_balance h2 {
  font-size: 24px;
  color: #33a7cc;
  margin: 0;
}

.cashout_modal .addcard_form input {
  position: relative;
}

.sale_history_sec .trans_date_list {
  width: 70px;
  height: 70px;
}

.cashout_modal .addcard_form .sr {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  color: #212121;
  opacity: 0.3;
  font-size: 14px;
}

.trans_date_list figure {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 9px;
  border: 1px solid #e2e2e2;
}

.trans_date_list figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cashout_balance span {
  display: block;
  font-size: 16px;
}

.cashout_balance figure {
  margin-right: 26px;
}

.sale_history_sec .theme_btn {
  color: #fff;
  padding: 0px 21px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content:center;
  font-size: 14px;
  line-height:1;
}

.post_footer p {
  font-size: 14px;
  color: #aca9b4;
}

.post_footer p a {
  color: #33a7cc;
}

.outline_wrapper .nav-tabs li button {
  border: none;
}

.outline_wrapper .nav-tabs {
  border-bottom: none;
}

.outline_wrapper .nav-tabs .nav-link.active {
  border-bottom: 2px solid #33a7cc !important;
  font-weight: bold;
  color: #33a7cc !important;
}

.outline_wrapper .nav-tabs li.nav-item {
  margin-right: 28px;
}

.outline_wrapper .nav-tabs .nav-item .nav-link {
  font-size: 16px;
  color: #d9d9d9;
  border-bottom: 2px solid #d9d9d9;
  padding: 4px 27px;
  font-weight: 600;
}

.likes_btn {
  display: flex;
  align-items: center;
}

.likes_btn .theme_btn {
  text-decoration: none;
  margin-right: 9px;
}

.likes_btn .theme_btn:last-child {
  margin-right: 0;
}

.my-favourite .trans_title span {
  color: #33a7cc;
  font-weight: 600;
}

.my-favourite .trans_date_list,
.my-favourite .trans_date_list figure {
  width: 96px;
  height: 96px;
  position: relative;
}

.my-favourite .trans_date_list figure small {
  position: absolute;
  right: 0px;
  top: 0px;
}

.my-favourite .trans_date_list figure small {
  position: absolute;
  right: 8px;
  top: 6px;
  color: #ffffff;
  font-size: 12px;
}

.my-favourite .trans_date_list figure small .favourite {
  color: #ff0000;
}

/* About */

.inner_sec {
  background-color: #0b90bb;
  height: 132px;
  display: flex;
  align-items: center;
}

.inner_sec h2 {
  font-size: 60px;
  font-weight: 400;
  margin: 0;
  color: #fff;
}

.about_us_sec {
  padding-bottom: 82px;
  padding-top: 20px;
}

.about_us_sec p {
  color: #545454;
  font-size: 16px;
}

.profile_heading figure {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 100%;
  margin: 0 auto;
  display: block;
}

.profile_heading figure img {
  width: 100%;
  height: 100%;
}

.shipping_btn {
  margin-bottom: 33px;
}

.border-bottom {
  border-bottom: 1px solid #dedede !important;
}

.shipping_btn span {
  font-size: 16px;
  color: #aca9b4;
  margin-bottom: 37px;
  display: inline-block;
}

.shipping_btn .theme_btn {
  border-radius: 6px;
  margin-right: 20px;
  padding: 4px 20px;
}

.shipping_btn span small {
  color: #33a7cc;
}

shipping_btn .theme_btn {
  border-radius: 6px !important;
}

.profile_heading .rating_star h2 {
  font-size: 32px;
}

.profile_heading .rating_star p {
  font-size: 18px;
  color: #979b9f;
  margin: 0;
}

.profile_heading .rating_star {
  margin-top: 13px;
}

.profile_item_rate ul {
  margin: 25px 0px 40px 0px;
  padding: 0;
  display: flex;
  justify-content: center;
  /* margin-top: 25px; */
}

.profile_item_rate li {
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  padding: 5px 28px;
  list-style-type: none;
  text-align: center;
  margin: 0px 6px;
}

/* .profile_item_rate li:last-child {
  margin: 0;
} */
.profile_item_rate li h4 {
  font-size: 24px;
  color: #33a7cc;
  margin: 0;
}

.profile_item_rate li span {
  display: block;
  font-size: 16px;
  color: #979b9f;
}

.badges_sec {
  border-top: 1px dashed #e2e2e2;
  border-bottom: 1px dashed #e2e2e2;
  padding: 30px 30px;
}

.badges_sec li {
  list-style-type: none;
  /* display: inline-block; */
  text-align: center;
  flex: 0 0 19%;
  max-width: 19%;
}

.badges_sec li span {
  display: block;
  font-size: 16px;
}

.badges_sec ul {
  padding: 0px;
  margin: 0;
  display: flex;
  justify-content: center;
}

.profile_inner_heading {
  font-size: 18px !important;
}

.badges_sec {
  text-align: center;
}

.badges_sec {
  border-top: 1px dashed #e2e2e2;
  border-bottom: 1px dashed #e2e2e2;
  padding: 30px 0px;
}

.badges_sec li {
  list-style-type: none;
  text-align: center;
  flex: 0 0 17%;
  max-width: 17%;
}

.badges_sec ul {
  padding: 0px;
  margin: 0;
  display: flex;
  justify-content: center;
}

.badges_sec {
  text-align: center;
}

.badges_sec li span {
  display: block;
  font-size: 16px;
}

.profile_feedback {
  padding: 30px 0px;
  text-align: center;
}

.profile_feedback ul {
  padding: 0px;
  margin: 0px;
  padding-top: 15px;
}

.profile_feedback ul li {
  list-style-type: none;
  display: inline-block;
  text-align: center;
  border: 1px solid #5ab3d3;
  border-radius: 20px;
  padding: 3px 5px;
  margin-right: 22px;
  padding-right: 11px;
  font-size: 16px;
  color: #525252;
}

.profile_feedback ul li span {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-color: #5ab3d3;
  /* display: flex; */
  color: #fff;
  line-height: 33px;
  border-radius: 100%;
  margin-right: 4px;
}

.active_post_slider {
  margin: 0px -30px;
  padding: 30px 20px 20px 20px;
  border-top: 1px solid #dedede;
}

.active_post_slider h2 {
  margin-bottom: 20px;
}

.active_post_slider .product_card {
  margin: 0 5px 50px 0px;
}

.active_post_slider .slick-slide>div {
  margin: 0 22px 0px 0;
}

.active_post_slider .slick-prev {
  left: 96.8%;
}

/* .active_post_slider .slick-prev:before{
    font-family: "Font Awesome 5 Free!important"; font-weight: 900!important; content: "\f007"!important;
} */

.active_post_slider .slick-prev,
.active_post_slider .slick-next {
  top: 0% !important;
  transform: translate(-18px, -50px) !important;
  background: #33a7cc !important;
  width: 26px !important;
  height: 35px !important;
}

.active_post_slider .slick-prev:before,
.active_post_slider .slick-next:before {
  font-family: none;
  opacity: 1 !important;
  color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}

.active_post_slider .slick-prev:hover,
.active_post_slider .slick-next:hover {
  background: #33a7cc !important;
}

.active_post_slider .slick-prev:before {
  background-image: url(../images/prev_icon.svg);
}

.active_post_slider .slick-next:before {
  background-image: url(../images/next_arrow.svg);
}

.active_post_slider .overlay_bottom h4 a {
  font-size: 14px;
}

.active_post_slider .product_info .price {
  font-size: 14px;
}

.active_post_slider .boost_badge,
.active_post_slider .sold_badge {
  font-size: 9px;
}

span.add_badge {
  background: #aca9b4;
  font-size: 10px;
  padding: 2px 8px;
  border-radius: 3px;
  color: #fff;
}

.manage_post_sec ul li {
  padding: 9px 20px 9px 20px !important;
}

.all_inner_heading {
  font-size: 24px;
  margin-bottom: 25px;
}

/* About */

/* contact_sec */

.contact_sec .contact_form input {
  height: 44px;
}

.contact_sec .contact_form .form-control {
  border-radius: 8px;
  border-color: #e2e2e2;
}

.contact_sec .contact_form .form-label {
  margin-bottom: 8px;
}

.contact_sec {
  padding: 20px 0px 150px 0px;
}

.contact_sec .contact_form .send_btn {
  border-radius: 8px;
  min-width: 260px;
  height: 50px;
}

.contact_sec .contact_form .form-control::placeholder {
  font-size: 14px;
  color: #aca9b4;
}

/* contact_sec */

/* change_passsord_modal */

.change_passsord_modal .modal-content {
  border-radius: 16px;
}

.change_passsord_modal .modal-body {
  padding: 2px 37px;
}

.change_passsord_modal .change_password_main {
  margin-top: 24px;
}

.change_passsord_modal .modal-dialog {
  max-width: 540px;
  margin-top: 8rem;
  margin-left: auto;
  margin-right: auto;
}

.password_restrication ul li span i {
  color: #aca9b4;
  font-size: 9px;
  margin-right: 9px;
}

.password_restrication ul {
  padding: 0px;
  margin: 0px;
}

.password_restrication .tooltip-inner {
  text-align: left;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  max-width: 100%;
  background-color: #fff;
}

.tooltip .tooltip-arrow:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f053";
  color: #e2e2e2;
  border: none;
}

.password_restrication ul li {
  list-style-type: none;
  color: #aca9b4;
  line-height: 25px;
  font-weight: 100;
}

/* change_passsord_modal */

/* create_post */

.create_post_sec .upload_post {
  background: #f7f8fa;
  border: 1px dashed #e9e9e9;
  border-radius: 16px;
  position: relative;
  height: 369px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create_post_sec .upload_post input[type="file"] {
  height: 100%;
  position: absolute;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.create_post_sec .upload_post span {
  display: block;
  text-align: center;
}

/* .create_post_sec .post_form .form-select{
    background-position: none!important;
} */
.create_post_sec .post_form .form-control,
.create_post_sec .post_form .form-select {
  color: #c2c2c2;
  font-size: 14px;
  border-color: #e9e9e9;
  border-radius: 8px;
  padding: 0px 20px;
}

.post_form textarea {
  padding: 17px 20px !important;
}

/* .create_post_sec .form_title_post .form-control, .form-select , .weight_form .form-control, .form-select{

} */
.same_input .form-control,
.form-select,
.MuiInputBase-formControl {
  height: 45px;
}

.weight_form .input-group-text {
  background: #f7f8fa;
  border-color: #e2e2e2;
  color: #aca9b4;
  font-size: 14px;
}

.create_post_sec .post_form .form-control::placeholder {
  color: #c2c2c2;
  font-size: 14px;
}

.create_post_sec .post_form .form-label {
  font-size: 15px;
  color: #111113;
  font-weight: 600;
  margin-bottom: 7px;
}

.condition_post {
  background: #f7f8fa;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
}

.condition_label label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: #fff;
  padding: 8px 15px 8px 20px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  height: 45px;
}

.condition_label label input[type="radio"] {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.condition_label span.radio_indicator {
  width: 16px;
  height: 16px;
  border: 1px solid #0000007a;
  border-radius: 100%;
  position: relative;
}

.condition_label input[type="radio"]:checked~.radio_indicator:before {
  width: 10px;
  height: 10px;
  background: #33a7cc;
  border-radius: 100%;
  position: absolute;
  content: "";
  opacity: 1;
  margin: 2px;
}

.condition_label label input[type="radio"]:checked+small {
  color: #33a7cc;
}

.outlineborder {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 1px solid transparent;
  border-radius: 8px;
}

.condition_label label input[type="radio"]:checked~.outlineborder {
  border-color: #33a7cc;
}

/* payment_meth0d */

.payment_prefrence label {
  flex-direction: row-reverse;
  justify-content: flex-end;
  background-color: #f7f8fa;
}

.payment_prefrence span.radio_indicator {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border-color: #e9e9e9;
  background: #fff;
}

.payment_prefrence label small {
  margin-left: 16px;
}

.payment_prefrence label input[type="radio"]:checked+small {
  color: #000000;
}

.payment_prefrence label input[type="radio"]:checked~.radio_indicator:before {
  background: #33a7cc;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f00c";
  width: 100%;
  height: 100%;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 3px;
  padding: 0px;
  margin: 0px;
}

/* payment_method */
/* create_post */

/* 28/10/21 */

/* 12/11/21 */

.seller-post-review h3 {
  font-size: 28px;
}

.seller-post-review .price {
  font-size: 32px;
}

.seller-post-review .post-review-item {
  line-height: 30px;
}

.seller-post-review p {
  background: #ecb213;
  color: #fff;
  border-radius: 3px;
  padding: 5px 11px;
  font-weight: 600;
  display: inline-block;
  font-size: 16px;
}

.seller-post-review .sel-post-date span:first-child {
  color: #aca9b4;
  margin-right: 10px;
}

.seller-post-review .sel-post-date span {
  font-size: 14px;
}

.seller-post-review .sel-post-date span i {
  font-size: 14px;
  margin-right: 4px;
}

.eye_icon {
  font-size: 16px;
  color: #33a7cc;
}

.eye_icon i {
  margin-right: 6px;
}

.payment_method .payment_list li a {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid #aca9b4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment_method ul li {
  list-style-type: none;
  margin-right: 4px;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 1px 9px;
}

.payment_social ul li a {
  color: #111113;
}

.payment_social ul li a i {
  margin-right: 7px;
}

.payment_social ul li:last-child {
  margin-right: 0px;
}

.payment_social ul li:hover {
  border-color: #e2e2e2;
}

.payment_social ul li:hover a {
  color: #33a7cc;
}

.post_review_side {
  padding: 20px;
}

.post_review_side .btn {
  border-radius: 8px;
}

.post_review_side .name {
  color: #aca9b4;
  font-size: 14px;
}

.post_user_name figure .img_circle {
  width: 60px;
  height: 60px;
}

.post_user_name figure .img_circle img {
  width: 100%;
  height: 100%;
}

.post_user_name .user_rating small {
  color: #33a7cc;
}

/* gallery */
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  text-align: left;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border: none;
}

.image-gallery-thumbnail+.image-gallery-thumbnail {
  margin-left: 21px;
}

.image-gallery-thumbnail {
  border: none;
  width: 70px;
  height: 70px;
}

.image-gallery-thumbnail:hover {
  outline: none;
  border: none;
}

.image-gallery {
  margin: -24px -30px 0px -30px;
}

.image-gallery-thumbnails-wrapper {
  position: relative;
  padding: 20px 32px 20px 32px;
  border-bottom: 1px solid #e2e2e2;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  display: none;
}

.image_gallery_sec {
  position: relative;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  border-radius: 10px;
}

.image-gallery-slide {
  border-radius: 7px 7px 0px 0px;
  overflow: hidden;
}

.image_gallery_sec .doffo_label {
  background: rgb(0 0 0 / 0.5);
  color: #fff;
  padding: 8px 20px;
  border-radius: 16px;
  font-size: 14px;
  /* opacity: .5; */
  position: absolute;
  /* top: 78%; */
  top: 64%;
  right: 16px;
  text-transform: uppercase;
}

/* gallery */

/* 12/11/21 */

/* Jatin Css */

.modal-dialog {
  max-width: 712px;
}

.modal-body {
  padding: 19px 30px 40px;
}

.modal-content {
  border-radius: 13px;
  border: 0px;
}

.modal-title {
  font-size: 25px;
  margin-bottom: 18px;
}

.archive_modal figcaption {
  margin-left: 15px;
}

.archive_modal figcaption h4 {
  font-size: 16px;
  color: #111113;
  margin: 0px;
}

.archive_modal figcaption h5 {
  font-size: 14px;
  font-weight: 400;
  color: #111113;
  margin: 2px 0 5px;
}

.archive_modal figcaption h6 {
  font-weight: 300;
  font-size: 12px;
  color: #aca9b4;
  margin: 0;
}

.archive_modal figure img {
  width: 66px;
  height: 66px;
  border-radius: 10px;
}

.link_color {
  color: #33a7cc;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}

.btn_danger {
  background-color: transparent;
  color: #ff0000;
  border: 1px solid #ff0000;
  padding: 4px 17px;
}

.btn_danger:hover {
  background-color: #ff0000;
  color: #fff;
}

.modal-body .theme_btn.theme_btn {
  font-size: 18px;
}

.markSold_modal .custome_radio {
  border: 1px solid #33a7cc;
  right: 12px;
  left: auto;
}

.master_radio {
  font-size: 18px;
  color: #111113;
  font-weight: 600;
  position: relative;
}

.markSold_modal h4 {
  font-size: 18px;
  color: #111113;
  font-weight: 600;
}

.markSold_modal ul li img {
  width: 46px;
  height: 46px;
  border-radius: 100%;
  margin-right: 10px;
}

.markSold_modal ul li h5 {
  font-size: 15px;
  margin: 0px;
}

.markSold_modal ul li h5 span {
  display: block;
  color: #33a7cc;
  font-size: 14px;
  font-weight: 400;
  margin-top: 3px;
}

.subscribe_modal .modal-title span {
  color: #33a7cc;
  display: block;
  font-size: 30px;
}

.subscribe_modal .modal-title {
  text-align: center;
  line-height: 32px;
}

.subscribe_modal .outer {
  border: 1px solid #e6e6e6;
  text-align: center;
  padding: 40px 15px 15px;
}

.subscribe_modal .outer h4 {
  color: #111113;
  font-size: 25px;
  font-weight: 400;
  margin: 0px;
}

.subscribe_modal .outer h4 span {
  font-size: 50px;
  display: block;
  color: #aca9b4;
  font-weight: 600;
}

.subscribe_modal .outer h3 {
  color: #33a7cc;
  font-size: 32px;
  font-weight: 600;
  border-top: 1px solid #e6e6e6;
  margin: 30px 0 0;
  padding: 15px 0;
}

.subscribe_modal .outer.active {
  border: 2px solid #111113;
  background-color: #33a7cc;
  color: #fff;
  padding: 50px ​15px 25px;
}

.subscribe_modal .outer.active h3 {
  margin: 50px 0 0;
  padding: 25px 0;
}

.subscribe_modal .outer.active h4 span,
.subscribe_modal .outer.active h4,
.subscribe_modal .outer.active h3 {
  color: #fff;
}

.subscribe_modal ul li {
  list-style: none;
  color: #33a7cc;
  font-size: 14px;
  line-height: 24px;
}

.text-large {
  font-size: 18px;
  color: #22b81d;
  font-weight: 600;
  text-decoration: underline;
}

.theme-color {
  color: #33a7cc;
  text-decoration: none;
}

.trans_btn {
  border: 1px solid #111113;
  color: #111113 !important;
  font-size: 18px;
}

.location_modal .input-group-text {
  background: #fff;
  border: 0px;
  width: 56px;
  filter: grayscale(1);
  position: absolute;
  z-index: 99;
  top: 7px;
  padding: 12px 18px;
}

.location_modal .form-control {
  border: 1px solid #e2e2e2;
  border-radius: 8px !important;
  padding: 15px 15px 15px 55px;
  color: #aca9b4;
  font-size: 19px;
}

.modal-title p {
  font-size: 16px;
  font-weight: 400;
  color: #111113;
}

.work_list li {
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 54px;
  font-size: 16px;
  color: #aca9b4;
  font-weight: 400;
  line-height: 27px;
  background: url("../images/list-icon.svg") no-repeat left top;
}

.upload_item_list li {
  list-style: none;
  width: 100px;
  height: 100px;
  border-radius: 9px;
  margin: 0 15px;
}

.upload_item_list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.markSold_modal .upload_item_list li img {
  border-radius: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}

.markSold_modal .upload_item_list li a img {
  width: auto;
  height: auto;
}

.upload_item_list li .upload_img {
  width: 100%;
  height: 100%;
  border: 1px solid #aca9b4;
  border-radius: 9px;
  background: #f7f8fa;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  flex-wrap: wrap;
  position: relative;
  text-decoration: none;
}

.upload_item_list li .upload_img span {
  text-align: center;
  color: #aca9b4;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}

.upload_item_list li .upload_img input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.markSold_modal ul li {
  position: relative;
  cursor: pointer;
}

.markSold_modal .radio_btn {
  position: static;
}

.markSold_modal .radio_btn input[type="radio"] {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
}

.modal-body .rating i.active {
  color: #ffbc01;
}

.modal-body .rating i {
  color: #e0dfe1;
  font-size: 20px;
  margin: 0 6px 0 0;
}

.taglist {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  align-items: flex-start;
}

.taglist a {
  color: #33a7cc;
  border: 1px solid #33a7cc;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding: 10px 10px;
  border-radius: 8px;
  text-decoration: none;
  display: block;
  margin: 0 5px 20px;
  background-color: #fff;
  min-width: 118px;
}

.taglist a:hover,
.taglist a.active {
  background-color: #33a7cc;
  color: #fff;
}

.verification_modal .modal-title span {
  color: #aca9b4;
  font-size: 14px;
  display: block;
  font-weight: 400;
}

.upload_picture {
  border: 2px dashed #e1e1e1;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 246px;
  padding: 15px;
}

.upload_doc .file_overlay,
.upload_picture input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.upload_picture h2 {
  font-size: 26px;
  color: #000000;
  font-weight: 400;
  margin: 18px 0 8px;
}

.upload_picture h5 {
  color: #185a9d;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

.verification_modal form label {
  margin: 0 0 10px;
  display: block;
  color: #111113;
  font-size: 18px;
  font-weight: 600;
}

.verification_modal form .form-control {
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  color: #aca9b4;
  font-size: 15px;
  font-weight: 400;
  padding: 14px;
  height: auto;
}

.verification_modal form .upload_doc span {
  font-size: 15px;
  color: #33a7cc;
  font-weight: 300;
  position: absolute;
  top: 8px;
  right: 1px;
  background: #fff;
  border: 0;
  padding: 7px 12px;
}

.modal-backdrop.show {
  opacity: 0.7;
}

/* jatin css  */

/* modals_desin */

.cash_out_modal .modal-dialog {
  max-width: 540px;
}

/* modals_design */

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 40px;
}

.settings-tray {
  background: #0b90bb;
  padding: 18px 36px;
  height: 80px;
  display: flex;
}

.settings-tray .no-gutters {
  padding: 0;
}

.settings-tray--right {
  float: right;
}

.settings-tray--right i {
  margin-top: 10px;
  font-size: 25px;
  color: grey;
  margin-left: 14px;
  transition: 0.3s;
}

.settings-tray--right i:hover {
  color: #74b9ff;
  cursor: pointer;
}

.search-box {
  background: #efefef;
  padding: 10px 13px;
}

.search-box .input-wrapper {
  background: #fff;
  border-radius: 40px;
}

.search-box .input-wrapper i {
  color: grey;
  margin-left: 7px;
  vertical-align: middle;
}

.friend-drawer {
  padding: 10px 15px;
  display: flex;
  vertical-align: baseline;
  transition: 0.3s ease;
}

.friend-drawer--grey {
  background: #eee;
}

.friend-drawer .text {
  margin-left: 12px;
  width: 70%;
}

.friend-drawer .text h6 {
  margin-top: 6px;
  margin-bottom: 0;
}

.friend-drawer .text p {
  margin: 0;
}

.friend-drawer .time {
  color: grey;
}

.friend-drawer--onhover:hover,
.curentbuyer {
  background: #f7f8fa;
  cursor: pointer;
}

.chat-bubble {
  padding: 10px 14px;
  background: #eee;
  margin: 10px 30px;
  border-radius: 9px;
  position: relative;
  animation: fadeIn 1s ease-in;
}

.chat-bubble:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-bottom: 0;
  margin-top: -10px;
}

.chat-bubble--left:after {
  left: 0;
  border-right-color: #eee;
  border-left: 0;
  margin-left: -20px;
}

.chat-bubble--right:after {
  right: 0;
  border-left-color: #74b9ff;
  border-right: 0;
  margin-right: -20px;
}

.offset-md-9 .chat-bubble {
  background: #74b9ff;
  color: #fff;
}

.chat-box-tray {
  background: #eee;
  display: flex;
  align-items: baseline;
  padding: 10px 15px;
  align-items: center;
  margin-top: 19px;
  bottom: 0;
}

.chat-box-tray input {
  margin: 0 10px;
  padding: 6px 2px;
}

.chat-box-tray i {
  color: grey;
  font-size: 30px;
  vertical-align: middle;
}

.chat-box-tray i:last-of-type {
  margin-left: 25px;
}

.chat-buyer-section {
  display: flex;
  justify-content: space-between;
}

.chatleftside.border-right {
  flex: 0 0 512px;
  max-width: 512px;
  border: 1px solid #dedede;
  border-radius: 8px 0 0 0;
  overflow: hidden;
}

.chat-buyer-right {
  flex: 0 0 100%;
  max-width: calc(100% - 512px);
  background: #f7f8fa;
  border-radius: 0 8px 0 0;
  overflow: hidden;
  border: 1px solid #dedede;
  border-left: none;
  position: relative;
}

.search-box input {
  padding: 12px 15px;
}

.chatleftside-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 15px 36px;
  background: #fff;
  position: relative;
}

.friend-drawer {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #dedede;
  padding: 24px 36px 24px 36px;
}

.friend-drawer figure {
  /* flex-grow: 1; */
  display: flex;
  align-items: flex-start;
  flex: 0 0 80%;
  max-width: 80%;
}

.friend-drawer figure .img_circle {
  width: 46px;
  height: 46px;
  overflow: hidden;
  border-radius: 50px;
  border: 2px solid #ddd;
}

.friend-drawer figure figcaption {
  padding-left: 10px;
  width: calc(100% - 46px);
}

.friend-drawer figure figcaption h5 {
  margin: 0;
}

.friend-drawer figure figcaption .user_rating i {
  font-size: 13px;
}

.message-text {
  margin: 0;
  font-size: 14px;
  color: #aca9b4;
}

.message-text span {
  margin-right: 8px;
}

.message-text span img {
  filter: grayscale(1);
  opacity: 0.6;
}

.date {
  color: #000000;
  font-size: 14px;
  padding: 5px 0;
}

.datefx-option {
  text-align: right;
  flex: 1;
}

.datefx-option button {
  background: transparent;
  border: none;
  color: #545454;
  padding: 5px 0;
}

.datefx-option button img {
  width: 4px;
}

.datefx-option button:after {
  display: none;
}

.chatleftside-head h4 {
  margin: 0;
  font-size: 18px;
}

.settings-tray figure {
  display: flex;
  align-items: flex-start;
}

.img_circle {
  width: 46px;
  height: 46px;
  overflow: hidden;
  border-radius: 50px;
  border: 2px solid #ddd;
}

.settings-tray figure figcaption {
  padding-left: 10px;
}

.settings-tray figure figcaption h5 {
  margin: 0;
  color: #fff;
  font-size: 18px;
}

.friend-drawer figure figcaption h5 {
  font-size: 18px;
}

.chat-buyer-section .user_rating {
  line-height: 1.2;
  margin-left: -2px;
}

.datefx-option .btn-success:focus {
  background: transparent;
  border: none;
  box-shadow: none;
}

.settings-tray .user_rating small {
  color: #fff;
}

.settings-tray .user_rating .fas.fa-star:not(.active_icon) {
  color: #82d5ef;
}

.right_btn_service .btn-warning {
  min-width: 90px;
  height: 40px;
  font-size: 16px;
  margin-right: 20px;
  line-height: 1.7;
}

.right_btn_service .bg-transparent {
  min-width: 90px;
  height: 40px;
  font-size: 16px;
  line-height: 1.7;
}

.buyer-service {
  padding: 10px 32px;
  background: #fff;
}

.right_btn_service .btn-warning {
  min-width: 95px;
  height: 40px;
  font-size: 16px;
  margin-right: 20px;
  line-height: 1.7;
}

.right_btn_service .bg-transparent {
  min-width: 90px;
  height: 40px;
  font-size: 16px;
  line-height: 1.7;
}

.buyer-service {
  padding: 10px 32px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 32px;
}

.buyer-thumb-outer {
  display: flex;
}

.buyer-thumb-outer figcaption {
  padding-left: 10px;
}

.buyer-service .img-thumb {
  max-width: 50px;
  max-height: 50px;
  border-radius: 8px;
  overflow: hidden;
}

.buyer-thumb-outer h5 {
  margin: 0;
  font-size: 17px;
  line-height: 1;
}

.buyer-thumb-outer h5 a {
  text-decoration: none;
  color: #111113;
}

.buyer-thumb-outer small {
  font-size: 14px;
}

.chat-panel.chat-byer-panel {
  padding: 32px;
}

.header_list.languge select.form-select {
  background-position: right;
}

.buyer-cardinfo {
  background: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  padding: 20px 15px;
  margin: 0 0 28px 0;
}

.buyer-detail-card h5 {
  font-size: 16px;
}

.buyer-detail-card h5 span {
  font-weight: normal;
  color: #aca9b4;
  padding: 0 5px;
}

.buyer-detail-card h5 a {
  color: #33a7cc;
  font-weight: normal;
}

.buyer-detail-card strong {
  font-weight: bold;
  color: #aca9b4;
  line-height: 1.2;
}

.paymethod small {
  font-size: 13px;
  color: #545454;
}

.paymethod span {
  margin-left: 50px;
}

.paymethod {
  display: flex;
  align-items: flex-start;
  font-size: 13px;
}

.paymethod span img {
  margin-right: 8px;
}

.moreinfo-accordian .accordion-button {
  padding: 0;
  border: none;
  width: auto;
  font-size: 12px;
  position: relative;
  background: transparent;
  border: none;
  box-shadow: none;
  margin-top: 2px;
}

.moreinfo-accordian .accordion-item {
  border: none;
  background: transparent;
  position: relative;
}

.moreinfo-accordian .accordion-button.collapsed:after,
.moreinfo-accordian .accordion-button:after {
  width: 10px;
  background-size: 100%;
  top: 1px;
  position: relative;
  margin-left: 5px;
  height: 8px;
}

.moreinfo-accordian .accordion-button strong {
  color: #33a7cc;
}

.moreinfo-accordian {
  margin-top: -23px;
}

.buyer-detail-card .buyer-thumb-outer {
  align-items: flex-start;
}

.moreinfo-accordian .accordion-item .accordion-header {
  position: relative;
  left: 88px;
}

.buyer_detail_contant ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.buyer_detail_contant ul li {
  padding: 2px 0;
  font-size: 14px;
}

.buyer_detail_contant ul li strong {
  color: #000;
}

.buyer-detail-footer {
  display: flex;
  border-top: 2px dashed #e2e2e2;
  margin: 10px 0 0 0;
  padding: 15px 0 0 0;
}

.buyer_detail_contant ul li span {
  width: 210px;
  display: inline-block;
}

.buyer_detail_contant ul li span {
  width: 210px;
  display: inline-block;
}

.buyer-detail-footer strong {
  color: #000;
  font-size: 16px;
  line-height: 1;
  flex: 0 0 200px;
  max-width: 200px;
}

.buyer-detail-footer span {
  color: #33a7cc;
  font-weight: bold;
  font-size: 16px;
}

.blur-border-btn {
  border: 1px solid #33a7cc;
  padding: 7px 10px;
  width: 140px;
  color: #33a7cc;
  height: 40px;
  border-radius: 7px;
}

.red-btn {
  background-color: #ff0000;
  color: #fff;
  border-color: #ff0000 !important;
  width: 140px;
  height: 40px;
  border-radius: 7px;
}

/* .buyer-card-list .buyer-thumb-outer {
  flex: 0 0 50%;
  max-width: 50%;
} */

.counter-btn.green-btn {
  background: #22b81d;
  border: 1px solid #22b81d;
  padding: 7px 10px;
  width: 140px;
  color: #ffffff;
  height: 40px;
  border-radius: 7px;
}

.buyer-card-list .buyer_btn {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
}

.buyer-card-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buyer-card-list .buyer_btn .btn {
  margin: 0 10px;
}

.moreinfo-accordian .accordion-item .accordion-header {
  display: inline-block;
  width: auto;
}

.accordion-collapse.collapse.show .buyer_btn {
  display: block;
}

.moreinfo-accordian .accordion-body {
  padding: 15px 0 0 5px;
}

.blur-fill {
  background-color: #33a7cc;
  color: #fff;
}

.meet_description .buyer-thumb-outer {
  align-items: center;
  flex: 0 0 50%;
  max-width: 50%;
}

.simple_text h6 {
  font-weight: normal;
  font-size: 20px;
  color: #707070;
  margin: 0;
}

.meet_description {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.counterbtn {
  flex: 0 0 50%;
  max-width: calc(50% - 20px);
}

.counterbtn .btn:first-child {
  margin-right: 20px;
}

.img-md-thumbnail {
  width: 77px;
  flex: 0 0 77px;
  border-radius: 12px;
  overflow: hidden;
}

.buyer-card-list .buyer_btn {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  justify-content: center;
}

.shipping_information h5 {
  margin: 0;
  font-size: 16px;
}

.shipping_information small {
  font-size: 14px;
  color: #aca9b4;
}

.shipping_info_btn {
  margin: 10px 0 10px 0;
}

.shipping_info_btn .blur-border-btn {
  width: 220px;
  padding: 8px 20px;
  margin: 10px;
}

.shipping_information h5 {
  margin: 0;
  font-size: 16px;
}

.shipping_information small {
  font-size: 14px;
  color: #aca9b4;
}

.shipping_info_btn {
  margin: 10px -10px 10px -10px;
}

.shipping_info_btn .btn {
  width: 220px;
  padding: 8px 20px;
  margin: 6px 10px;
  border-radius: 7px;
}

.shipping_information p {
  margin: 0;
  color: #aca9b4;
  font-size: 14px;
}

.red-border-btn {
  border: 1px solid #ff0000;
  color: #ff0000;
}

.shipping_info_btn .blur-border-btn.large_btn {
  width: 460px;
}

.theme_btn.btn.reschedule {
  border-radius: 8px;
  margin: 30px 0 15px 0;
  width: 142px;
  color: #fff;
}

.theme_btn.btn.reschedule {
  border-radius: 8px;
  margin: 30px 0 15px 0;
  width: 142px;
  color: #fff;
}

.meet-up-location-head {
  display: flex;
  justify-content: space-between;
  padding: 0 0 15px 0;
  align-items: self-start;
}

.location-info-list ul {
  padding: 0;
  list-style: none;
  display: flex;
  margin: 0 -10px 15px -10px;
  flex-wrap: wrap;
}

.location-info-list ul li {
  padding: 5px 10px;
  flex: 0 0 33.333%;
  max-width: 33.333%;
  font-size: 14px;
  color: #aca9b4;
}

.location-info-list ul li span {
  color: #212121;
  padding-left: 5px;
}

.date-time {
  font-size: 13px;
  color: #aca9b4;
  font-weight: 200;
}

.button-right {
  background: transparent;
  border: none;
  padding: 0;
  margin-right: 32px;
}

.button-right:focus {
  outline: none;
}

.buyer_sidebar .chatleftside.border-right {
  flex: 0 0 306px;
  max-width: 306px;
}

.buyer_sidebar .chatleftside.border-right .btn.btn_search {
  display: none;
}

.buyer_sidebar .chatleftside.border-right .datefx-option {
  display: none;
}

.buyer_sidebar .chat-buyer-right {
  max-width: calc(100% - 306px);
  flex: 0 0 100%;
}

.panel_bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100% - 63px);
  margin: auto;
  box-shadow: 0 0 10px -2px rgb(0 0 0 / 10%);
  background: #fff;
}

.chat-panel.chat-byer-panel {
  /*position: relative; */
  padding-bottom: 60px;
  height: calc(100% - 185px);
  overflow-y: auto;
}

.chating_list {
  height: 100%;
}

.type-tool {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 8px 20px;
  justify-content: space-between;
  height: 62px;
}

.msg-tip {
  padding: 0 32px;
  margin: 0 -8px;
  margin-bottom: 20px;
}

.type-tool input[type="text"] {
  flex: 0 0 100%;
  max-width: calc(100% - 50px);
  padding: 10px;
  border: none;
  font-size: 14px;
}

.type-tool .btn {
  padding: 0;
}

.type-tool input[type="text"]:focus {
  outline: none;
}

.msg-tip a {
  border: 1px solid #33a7cc;
  text-decoration: none;
  color: #33a7cc;
  padding: 6px 15px;
  margin: 0 8px;
  display: inline-block;
  height: 40px;
  border-radius: 7px;
  background: #fff;
}

.buyer_sidebar .button-right {
  transform: rotate(180deg);
}

.chating_list li {
  list-style: none;
  padding: 15px 0;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  padding-right: 60px;
}

.chating_list ul {
  padding: 0;
}

.chat-messagets p {
  margin: 0;
  display: inline-block;
  width: auto;
  background: #33a7cc;
  min-height: 40px;
  padding: 9px 24px;
  color: #fff;
  font-size: 14px;
  border-radius: 1rem;
  border-bottom-left-radius: 0.125rem;
  margin-left: 10px;
  border: 1px solid #33a7cc;
}

.date-fix {
  display: block;
  font-size: 12px;
  color: #aca9b4;
  padding: 5px 0 0 10px;
}

.chating_list li.sender-msg {
  text-align: right;
  max-width: 100%;
  flex-direction: row-reverse;
  max-width: 100%;
  padding-right: 0 !important;
  padding-left: 60px;
}

.chating_list li.sender-msg .chat-messagets p {
  margin-left: 0;
  margin-right: 10px;
}

.chating_list .date-fix {
  padding-left: 0;
  padding-right: 10px;
}

.chating_list li.sender-msg .chat-messagets p {
  background: #fff;
  border-color: #dedede;
  color: #111113;
  border-radius: 1rem;
  border-bottom-right-radius: 0.125rem;
}

.chat-messagets {
  flex: 0 0 100%;
  max-width: calc(100% - 40px);
}

.search-box input[type="text"] {
  width: 100%;
  border: none;
}

.search-box .input-wrapper {
  overflow: hidden;
}

.offer_page_wrapper .outline_wrapper {
  background: #f7f8fa;
}

.offer_page_wrapper .money_add li {
  background-color: #fff;
}

.offer_page_wrapper .tracking_head {
  margin: -24px -30px 0;
}

.offer_page_wrapper .buyer-service {
  margin: 0 -30px 32px -30px;
}

.offer-right_btn_service {
  display: flex;
  align-items: center;
}

.offer-right_btn_service .nav_dots {
  background-color: transparent;
  border: none;
  padding: 0;
}

.offer-right_btn_service .nav_dots:after {
  display: none;
}

.offer-right_btn_service a {
  margin: 0 15px;
}

.offer-right_btn_service {
  display: flex;
  align-items: center;
}

.offer-right_btn_service .nav_dots {
  background-color: transparent;
  border: none;
  padding: 0;
  margin-top: -2px;
}

.offer-right_btn_service .nav_dots:after {
  display: none;
}

.offer-right_btn_service a {
  margin: 0 15px;
}

.offer-right_btn_service .edit {
  background: no-repeat;
  border: none;
  color: #33a7cc;
  font-size: 16px;
  text-decoration: none;
}

.offer-right_btn_service .edit i {
  margin-right: 5px;
  font-size: 14px;
}

.offer-right_btn_service .theme_btn {
  color: #fff;
  padding: 0px 21px;
  height: 30px;
  display: flex;
  min-width: 95px;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.view-count {
  display: block;
  font-weight: normal;
  color: #aca9b4;
  font-size: 12px !important;
  padding: 3px 0 0 0;
}

.offer-top-hd .red_text {
  color: #ff0000;
  font-size: 13px;
  display: block;
}

.green_bg {
  background-color: #22b81d !important;
  border-color: #22b81d !important;
}

.red_bg.btn {
  background-color: #ff0000;
  border-color: #ff0000;
}

.offer_page_wrapper .likes_btn .btn {
  font-size: 14px;
  min-width: 95px;
  justify-content: center;
  margin: 0 10px;
}

.offer-page-outline .boost_duration {
  padding: 12px 0;
}

.offer-page-outline .boost_duration .timing-vl {
  font-size: 14px;
  color: #aca9b4;
}

.offer-page-outline .money_add .trans_title {
  padding-top: 0 !important;
}

.offer-page-outline .money_add .price {
  font-size: 13px;
  font-weight: bold;
  color: #33a7cc;
}

.paymethod.applypay {
  color: #aca9b4;
  line-height: 1;
}

.offer-page-outline .paymethod small {
  color: #aca9b4;
}

.offer-page-outline .trans_date_list figure {
  width: 46px;
  height: 46px;
  border-radius: 50px;
  border: 2px solid #cecece !important;
}

.offer-page-outline .trans_date_list {
  width: 46px !important;
  height: auto;
}

.offer-page-outline .trans_title {
  margin-left: 10px !important;
}

.offer-page-outline .trans_date_list h3 {
  display: block;
  line-height: 1;
}

.offer-page-outline .money_add .trans_date .trans_title h3 {
  line-height: 1;
}

.gride_mrketing img {
  margin-right: 8px;
}

.gride_mrketing {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.location-picker {
  width: 150px;
}

.location-picker img {
  width: 20px;
  margin-right: 10px;
}

.location-picker {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 5px;
}

.default_address_wrap .order_detail_box {
  box-shadow: 0 0 5px rgb(51 167 204);
}

section.confirm_order_section {
  overflow-x: hidden;
}

.ml-1 {
  margin-left: 12px;
}

/* dev Css */
.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 3px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 1rem !important;
  color: #000 !important;
  top: -5px !important;
  /* padding: 10.5px 4px 7.5px 6px !important; */
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  border-color: #e9e6e6 !important;
}

.css-bf0if6-MuiFormControl-root {
  margin: 0px !important;
}

.ReactRibbons_ribbonContainer_3Zi1le5BXstWhvxMbxE3QQ {
  min-height: 5px !important;
}

.flag-dropdown {
  border-color: #000 !important;
}

.custom-date-picker .MuiInputBase-root {
  height: 45px !important;
  border-radius: 8px !important;
  padding: 5px !important;
  border: 1px solid #000 !important;
  border-bottom: none !important;
}

.MuiPickersDay-daySelected {
  background-color: #33a7cc !important;
  color: #ffffff !important;
}

.MuiPickersDay-daySelected:hover {
  background-color: #33a7cc !important;
  color: #ffffff !important;
}

::-webkit-scrollbar {
  /* width: 7px; */
  display: none;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12.5px 14px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 20px !important;
}

/* .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: red !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-roots {
  border-color: red !important;
} */
/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #33a7cc;
  border-radius: 4px;
} */

.carousel .thumbs {
  padding: 0px !important;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 3px solid #33a7cc !important;
}

/**====2/3/2022======**/

.is-arabic .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding-right: 56px !important;
}

.is-arabic .create_post_sec .post_form .form-select {
  padding-right: 32px !important;
}

.is-arabic .email_notify {
  margin-right: 8px;
}

.is-arabic .breadcrumb ul li:first-child i {
  margin-right: 6px;
}

.is-arabic .add_new_btn .btn {
  margin-left: 7px;
}

.is-arabic .seller-post-review .sel-post-date span i {
  margin: 0 5px;
}

.is-arabic .payment_social ul li a i {
  margin: 0 5px;
}

.is-arabic .listing_brands_new .listing_brand_cat ul li .preview-label {
  padding-left: 8px;
  padding-right: 0;
}

.is-arabic .weight_form .input-group-text {
  border-radius: 5px 0 0 5px !important;
}

.is-arabic .create_post_sec .post_form .form-control,
.is-arabic .create_post_sec .post_form .form-select {
  border-radius: 0 5px 5px 0 !important;
}

.is-arabic .eye_icon i {
  margin-left: 8px;
}

.is-arabic .sidebar_sie_menu h5 span img {
  margin-left: 8px;
  margin-right: 0;
}

.is-arabic .MuiInput-root.MuiInput-underline {
  flex-direction: row-reverse;
}

.is-arabic .order_detail_head .edit .fa-pen,
.is-arabic .money_add .edit .fa-pen {
  margin-left: 5px;
  margin-right: 0;
}


h3 {
  text-transform: capitalize;
}

/* width */
.ChatContent [class*="react-scroll"]::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.ChatContent [class*="react-scroll"]::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.ChatContent [class*="react-scroll"]::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 50px;
}

/* Handle on hover */
.ChatContent [class*="react-scroll"]::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ChatContent {
  position: relative;
  padding: 0px;
}

.ChatContent form {
  background: #fff;
}

form.chatform {
  background: #ececec !important;
  padding: 18px 15px;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 105px;
  right: 0;
  max-width: 500px;
  margin: auto;
  bottom: 70px;
}

.chat-form-group {
  background: #fff;
  padding: 12px 10px;
  border-radius: 100px;
  min-width: 400px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
  margin-right: 15px;
}

.chat-form-group input {
  border: none;
  background: transparent;
  padding: 0 15px;
  width: calc(100% - 36px);
  font-weight: 500;
  color: #000;
}

.chat-form-group input:focus {
  outline: none;
  box-shadow: none;
}

.chat-form-group .send-btn {
  border: none;
  background: transparent;
}

.chat-form-group .send-btn i {
  color: #50663c;
  font-size: 20px;
}

.choose-file {
  background: #fff !important;
  border-radius: 100px;
  width: 50px;
  height: 50px;
  overflow: hidden;
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
  position: relative;
}

.choose-file .fa.fa-paperclip {
  font-size: 22px;
  position: absolute;
  /* top: 50%; */
  /* left: 50%; */
  padding: 14px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.choose-file input {
  opacity: 0;
}

.message.sent,
.message.received {
  font-size: 14px;
  padding: 6px 15px;
  border-radius: 10px 10px 0 10px;
  background: #eeffdc;
  margin: 0;
  float: right;
  box-shadow: none;
  /* min-width: 150px; */
  display: block;
  clear: both;
  text-align: right;
  max-width: calc(100% - 100px);
  text-align: justify !important;
}

.dateandtime {
  position: absolute;
  font-size: 11px !important;
  color: #888 !important;
  right: 0;
  bottom: -6px;
}

.message-list {
  position: relative;
  padding: 15px 0;
  margin: 32px 0;
}

.message-list:after,
.message-list:before {
  display: table;
  content: "";
  clear: both;
}

.message.received .dateandtime {
  right: auto;
  left: 0px;
}

.message.sent p,
.message.received p {
  margin: 0;
}

.message.received {
  float: left;
  background: #f5f5f5;
  text-align: left;
  border-radius: 10px 10px 10px 0;
}

.message.sent *,
.message.received * {
  color: #000 !important;
  font-size: 14px;
  font-weight: normal;
}

.ChatContent>button {
  position: absolute;
  right: 0px;
  background: #50663c !important;
  padding: 20px;
}

.followButton:before {
  content: "";
}

.followbtn:before {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #fff;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}

.ChatContent .chat-outerbx {
  background-color: #fff;
  margin: auto;
  padding: 24px;
  height: 100%;
}

.chatingmember {
  width: 36px;
  height: 36px;
  border-radius: 100px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  top: 15px;
}

.message.sent .chatingmember {
  display: none;
}

.dateandtime span {
  color: #888 !important;
  font-size: 12px !important;
}

.message.sent i,
.message.received i {
  font-weight: 900;
  margin-right: 10px;
}

.hasAttach span {
  cursor: pointer;
}

.sendername {
  position: absolute;
  top: -8px;
  font-weight: 500 !important;
  font-size: 13px !important;
  left: 0;
}

.message.received.hasAttach i,
.message.sent.hasAttach i {
  margin-right: 0 !important;
}

.message.received.hasAttach {
  background-color: #f5f5f5 !important;
}

.right-contant {
  padding-bottom: 64px;
}

.ChatContent {
  height: 100%;
}

.choose-file {
  cursor: pointer;
}

.choose-file input {
  height: 100%;
  cursor: pointer;
}

.chat-menu .MuiPaper-root.MuiMenu-paper {
  min-width: 180px;
}

.chat-menu .MuiPaper-root.MuiMenu-paper .MuiMenuItem-root {
  width: 100%;
  justify-content: space-between;
}

.chatCount {
  position: relative;
}

.chat-menu .MuiPaper-root.MuiMenu-paper li:focus {
  background: transparent;
}

.chat-menu .MuiPaper-root.MuiMenu-paper li {
  border-bottom: 1px solid #f3f3f3;
  padding: 10px 15px;
}

.chat-menu .MuiPaper-root.MuiMenu-paper li:last-child {
  border: none;
}

span.chatCount svg {
  width: 16px;
}

@media screen and (max-width: 479px) {

  .message.sent,
  .message.received {
    max-width: calc(100% - 36px);
  }

  .message.sent,
  .message.received {
    max-width: calc(100% - 36px);
  }

  .chat-form-group {
    min-width: 220px;
    padding: 5px 10px 8px 20px;
  }

  .chat-form-group input {
    padding: 0;
    width: 100%;
  }

  form.chatform {
    padding: 20px 10px;
  }

  .ChatContent .chat-outerbx {
    height: 100vh;
  }

  .choose-file .fa.fa-paperclip {
    padding: 13px 5px;
    font-size: 15px;
  }

  .choose-file {
    width: 40px !important;
    height: 40px;
  }

  .chat-form-group input {
    font-size: 13px;
  }
}


/* 20/4/22 */

.react-reveal .searchOption {
  height: 44px;
}

.react-reveal .searchOption select {
  background-color: transparent;
}

.css-2613qy-menu {
  overflow-y: auto;
  height: 50px !important;
}

.manage-post-img {
  object-fit: cover;
}

.order_caption figure {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 10px;
}

.manage-row {
  display: flex;
}

.manage-row .manage-column {
  width: calc(100% - 100px);
  padding-left: 15px;
}

.manage-post-flex {
  display: flex;
  justify-content: space-between;
}

.confirm_order_section i {
  margin-right: 5px;
}

.image-thubnail-gallery-outer {
  display: flex;
  overflow-x: auto;
}

.image_gallery_sec img {
  height: 453px;
  object-fit: cover;
  width: 100%;
}

.boost_duration .ribbon {
  width: 39%;
}

.remaining-boost {
  display: flex;
  flex-wrap: wrap;
}

.remaining-boost img {
  max-width: 200px;
}

.remaining-boost li {
  max-width: calc(50% - 18px);
  margin: 7px 9px;
}

/* 20/4/22 */

/* 25/4/22 */


.mobile-chat-buyer .img-md-thumbnail {
  width: 78px;
  height: 78px;
  flex: 0 0 78px;
}

.mobile-chat-buyer .accordion-header {
  margin-top: 22px;
}

.mobile-chat-buyer .red-btn:hover {
  color: #fff;
}

.chat-message_list .buyer-cardinfo.buyer-detail-card {
  width: 100%;
}

.buyer-thumb-outer table {
  margin-bottom: 10px;
}

.mobile-chat-buyer .img-md-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chat-message_list .chating_list li {
  padding-right: 0px;
}

.user-circle {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
}

.accepted-offer-main-div-cs .theme_btn {
  margin: 5px 0px;
}

.schedule-meetup-modal .theme_btn {
  width: 100%;
}

.datefx-option span {
  display: inline-block;
  background: #37a9cd;
  padding: 1px 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
}

.offer-review-modal .offer-review-content {
  display: flex;
  align-items:flex-start;
}

.offer-review-modal .offer-review-content img {
  /* max-width: 50px; */
  width: 50px;
}

.offer-review-modal .offer-review-content .fig {
  width: calc(100% - 50px);
  padding-left: 10px;
}

.review-ul li {
  line-height: 29px;
  justify-content: space-between;
  display: flex;
}

.theme_btn:hover {
  color: #fff;
}

.rating .css-1vooibu-MuiSvgIcon-root {
  width: 1.5em;
  height: 1.5em;
}




/** 12/5/2022**/
.dialoog-footer {padding: 10px 24px 20px 24px!important;}
.with-crose .crose_btn {margin: 15px 15px 0 0;position: absolute;right: 0;top: 0;}
.MuiDialog-paperWidthSm {
  max-width: 712px!important;
}
.subscribe_modal .MuiDialog-paper {
  margin: 0!important;
  padding: 50px 30px 40px;
}
.subscribe_modal .MuiPaper-rounded {
  border-radius: 13px;
}
.subscribe_modal .MuiDialog-paperWidthSm {
  width: 712px!important;
  max-width: initial!important;
}
.modal-body {padding: 50px 30px 40px;}
.subscribe_modal a {
  text-decoration: none;
  font-size: 16px;
}.remaining-dialog .MuiPaper-root.MuiDialog-paper {width: 320px;}
.remaining-dialog .MuiPaper-root.MuiDialog-paper h3 {text-align: center;margin: 0;}
.remaining-boost .title-r-boost img {max-width: 32px;margin-right: 6px;}
.line-height-1{line-height:1;}
.offer-list-cs .MuiDialog-paperWidthMd{border-radius: 10px;}

.buyer-card-list.ORDER_ARRIVED {flex-direction: column;align-items: flex-start;}

.buyer-card-list.ORDER_ARRIVED .buyer_btn .btn {margin-left: 0;}

.buyer-card-list.ORDER_ARRIVED .buyer_btn {margin-top: 10px;}



.arabic .choose-language img,.arabic  .gride_mrketing img,.arabic  .location-picker img  {margin-right: 0;margin-left: 10px;}

.arabic .MuiDialog-root.address_box .cancel-button {margin-left: 0;margin-right: 10px;}

.arabic .login_form .form-control {margin-left: 0!important;margin-right: 8px;}

.arabic .text_view {right: auto;left: 15px;}

.language_dropdown .MuiList-root.MuiList-padding {flex-direction: column; min-width: 130px; text-align: center;}
  .language_dropdown .MuiList-root.MuiList-padding li {display: block;padding: 5px 10px;}
  .arabic .trans_history_money .money_add .trans_date .trans_title {margin-left: 0;margin-right: 18px;}

  .arabic .likes_btn .theme_btn {margin-left: 0;margin-right: 10px;}

  .arabic .MuiTypography-h6 {padding-right: 50px;}

  .arabic .inner-span-content {padding-left: 0;margin-right: 10px;}

.report-items .form-label {font-weight: 600;}

.report-items .upload_post {border: 2px dashed #efefef;margin: 10px 0;padding: 24px;text-align: center;border-radius: 10px;font-weight: bold;}

.report-items .upload_post svg {display: block;margin: auto;font-size: 44px;margin-bottom: 6px;    fill: #33a7cc;}
.arabic .modal-header {padding-right: 50px;}
.permission_modal .theme_btn {border-radius: 5px;padding: 0 20px;line-height: 1;font-weight: 600;}
.permission_modal .bg-red{background-color:#c62828; color: #fff;}
 .custom-date-picker .MuiInput-underline:after {display: none;}
 .myaccount_group .MuiInput-underline:before {border-bottom: 1px solid #040404;border-radius: 5px;}
 .corse-abs {position: absolute!important;top: 8px!important;right: 8px!important;padding: 7px!important;border-radius: 100px!important;}
 .ReactModalPortal .ReactModal__Overlay.ReactModal__Overlay--after-open {z-index: 9999!important;}
 .schdulePickHeight{  height: 400px; }
 #bottom {  position: absolute;  bottom: 0;}
 .PackageInformation{text-align: center; padding: 2rem 0rem; width: 100%; display: block;}
 .shortImage{ width:50px; height: 50px;}
 .iconDetails {  margin-right:2%; float:left;  height:60px; width:60px;  }  
 .container2 {     width:100%;     height:auto;     padding:1%; }
 .a1{margin-left:60px;}
 .a2{}
 .a3{float:right;}
 .blur-border-btn:hover{
  border: 1px solid #33a7cc;
 }








.page_404{padding: 50px 0;
  background: #fff;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{

 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
 }


 .four_zero_four_bg h1{
 font-size:80px;
 }

  .four_zero_four_bg h3{
			 font-size:80px;
			 }

			 .link_404{
	color: #fff!important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;}
	.contant_box_404{ margin-top:-50px;}

  .thanku_page {
    padding: 64px 0;
}

.thanku_page h2 {
    text-transform: capitalize;
}

.thanku_page i {
    font-size: 94px;
    margin-bottom: 24px;
}

.thanku_page .success-text {
    display: block;
    width: 360px;
    margin: auto;
    background: #fbfbfb;
    padding: 24px;
    box-shadow: 0 0 15px rgba(0,0,0,.1);
    border-radius: 15px;
}

.success-text p {
    font-weight: 5;
    color: #838383;
}
.delete_all_btn{
  background-color:var(--appRed)!important;
  border-color:var(--appRed)!important
}