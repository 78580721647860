.example-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0px 50px; */
}
.example-image {
  height: 150px !important;
  width: 150px !important;
  border: 1px solid lightGray;
  border-radius: 8px;
}
.example-image-icon {
  position: relative;
  text-align: center;
  font-size: 14px;
}
.example-icon-green {
  position: absolute;
  bottom: 2%;
  left: 43%;
  border: 1px solid lightGray;
  padding: 5px;
  background-color: #03b703;
  color: #ffffff;
  border-radius: 50%;
}
.example-icon {
  position: absolute;
  bottom: 2%;
  left: 43%;
  border: 1px solid lightGray;
  padding: 5px;
  background-color: #f16969;
  color: #ffffff;
  border-radius: 50%;
}
.example-para {
  margin-bottom: 5px;
  text-align: justify;
  display: flex;
  align-items: center;
}
.para-icon {
  margin-right: 20px;
}
.example-main-para {
  font-weight: bold;
  font-size: 18px;
}

.example-image-id {
  height: 111px !important;
  width: 111px !important;
  border: 1px solid lightGray;
  border-radius: 8px;
}
