@media screen and (min-width: 1200px) {
  .container {
    max-width: 1310px;
  }
}

@media screen and (min-device-width: 1440px) and (max-device-width: 1745px) {
  .container-fluid {
    max-width: 1410px;
  }

  .header_search_form {
    max-width: 35%;
    flex: 0 0 35%;
  }
  .tabs_outer .tab_outer_heading h2 {
    font-size: 28px;
  }
  .tabs_outer .nav-tabs .nav-link {
    padding: 13px 30px;
  }
  .main_contant .nav.nav-tabs {
    margin-bottom: 40px;
  }
  .tab_product_filter h2 {
    font-size: 28px;
  }

  .header_list.subscription {
    padding: 0 10px;
  }
  .header_list.header_droap_box {
    padding: 0 10px;
  }
  .select_market {
    margin-right: 10px;
  }
  .outline_btn {
    padding: 7px 16px;
  }
  .nav-brand {
    margin-right: 5px;
  }
  .header_search_form .form-control {
    max-width: calc(100% - 154px);
    font-size: 14px;
  }

  .formsticky {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1439px) {
  .container-fluid {
    max-width: 1200px;
  }
  .order_caption figure {
    width: 80px;
    height: 80px;
}
.manage-row .manage-column{
  width: calc(100% - 80px);
}
.filter_product .form-label{
  font-size: 11px;
}
  .tabs_outer .tab_outer_heading h2 {
    font-size: 26px;
  }
  .outline_wrapper h2 {
    font-size: 22px;
  }
  .active_post_slider .slick-prev {
    left: 95.8%;
  }
  .tabs_outer .nav-tabs .nav-link {
    padding: 13px 30px;
  }
  .main_contant .nav.nav-tabs {
    margin-bottom: 40px;
  }
  .tab_product_filter h2 {
    font-size: 26px;
  }
  .tabs_outer .nav-item .nav-link > span img {
    max-width: 44px;
    min-height: 44px;
  }
  .formsticky {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }
  .custome_price {
    padding-left: 9px;
  }
  .make_offer_form ul li:not(.custome_price) {
    margin: 0 5px;
  }
  .header_search_form {
    max-width: 32%;
    flex: 0 0 32%;
  }

  .header_list.subscription {
    padding: 0 10px;
  }
  .header_list.header_droap_box {
    padding: 0 5px;
  }
  .select_market {
    margin-right: 0px;
  }

  .outline_btn {
    padding: 7px 16px;
  }

  .nav-brand {
    margin-right: 0px;
  }
  .header_search_form .form-control {
    max-width: calc(100% - 132px);
    font-size: 14px;
  }

  .header_search_form {
    padding: 0 10px 0 15px;
    max-width: 32%;
    flex: 0 0 32%;
  }
  .header_search_form .form-select {
    max-width: 100px;
    background-size: 16px;
    padding-left: 24px;
  }

  .header_list {
    padding: 0 10px;
  }
  .header_search_form {
    height: 50px;
  }
  .header_search_form .btn.btn_search {
    padding-right: 0;
  }
  .header_droap_box .btn {
    padding: 10px;
  }
  .header_list.subscription .outline_btn {
    padding: 7px 10px;
  }
  .nav-brand img {
    max-width: 115px;
  }

  .banner_capion {
    margin-left: 100px;
  }

  /**==========buyer-pages==========**/
  .chatleftside.border-right {
    flex: 0 0 440px;
    max-width: 440px;
  }

  .chat-buyer-right {
    flex: 0 0 100%;
    max-width: calc(100% - 440px);
  }

  .blur-border-btn,
  .counter-btn.green-btn {
    width: 110px;
  }

  .msg-tip a {
    font-size: 14px;
    padding: 8px 15px;
  }
  .container {
    max-width: 1170px;
  }
  .buyer-card-list .buyer_btn .btn {
    margin: 0 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  header .container-fluid {
    max-width: 990px;
  }
  .search-chat-crose-icon {
    right: 27px;
}
  .mobile-chat-buyer .img-md-thumbnail {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
}
  .upload_post img {
    width: 80px!important;
    height: 80px!important;
}
  .chatleftside.border-right {
    flex: 0 0 350px;
    max-width: 350px;
}
.chat-buyer-right {
  flex: 0 0 100%;
  max-width: calc(100% - 350px);
}
  .manage-post-heading  .manage-pl {
    font-size: 17px;
    padding: 0px;
}
.manage-post-heading  .eye_icon{
  font-size: 14px;
}
  .order_caption figure {
    width: 50px;
    height: 50px;
}
.manage-row .manage-column{
  width: calc(100% - 50px);
}
  .ribbon-content {
    font-size: 12px!important;
  }
  .ribbon{
    width: 100%;
  }
  .filter_product .form-label {
    font-size: 9px;
  }
  .react-reveal .theme_btn{
    padding: 7px 8px;
    font-size: 15px;
  }

  .tabs_outer .tab_outer_heading h2 {
    font-size: 26px;
  }
  .image-gallery {
    margin: -20px -20px 0px -20px;
  }
  .active_post_slider .slick-prev {
    left: 94.8%;
  }
  .active_post_slider {
    margin: 0px -20px;
  }
  .profile_heading figure {
    width: 100px;
    height: 100px;
  }
  .profile_heading .rating_star h2 {
    font-size: 25px;
  }
  .outline_wrapper h2 {
    font-size: 20px;
}
  .profile_heading .rating_star p {
    font-size: 16px;
  }
  .profile_item_rate li h4 {
    font-size: 20px;
  }
  .profile_feedback ul li {
    margin-right: 10px;
    font-size: 14px;
  }
  .profile_feedback ul li span {
    width: 28px;
    height: 28px;
    line-height: 29px;
    font-size: 13px;
  }
  .profile_feedback {
    padding: 23px 0px;
  }
  .badges_sec li {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .listing_brands_new {
    margin: 0px -20px;
    margin-top: 20px;
    padding: 18px 20px;
  }
  .home_price_filter .distance_level {
    flex: 0 0 200px;
    max-width: 200px;
  }
  .home_price_filter .filter_btn {
    padding-left: 22px;
  }
  .tabs_outer .nav-tabs .nav-link {
    padding: 9px 30px;
  }
  .main_contant .nav.nav-tabs {
    margin-bottom: 40px;
  }
  .tab_product_filter h2 {
    font-size: 26px;
  }
  .tabs_outer .nav-item .nav-link > span img {
    max-width: 40px;
    min-height: 40px;
  }
  .make_offer_form ul li:not(.custome_price) {
    flex: 0 0 120px;
    max-width: 120px;
  }
  .my_account_sec .myaccount_group .form_field {
    flex: 0 0 45%;
    max-width: 45%;
  }
  /* .my_account_sec .myaccount_group {
            flex-wrap: wrap;
        } */
  .my_account_sec .col-lg-7 {
    width: 100%;
  }
  /* .account_step .last_name_user{
            margin-left: 0px;
        } */

  .custome_price {
    flex: 0 0 170px;
    max-width: 170px;
    padding-left: 7px;
  }

  .make_offer_form ul li:not(.custome_price) {
    font-size: 14px;
  }

  .formsticky {
    flex: 0 0 35%;
    max-width: 35%;
  }
  header {
    padding: 10px 0;
  }

  .header_search_form {
    height: 50px;
  }
  .select_market {
    margin-right: 0;
  }

  header {
    padding: 10px 0;
  }

  .header_search_form {
    height: 50px;
  }

  .nav-brand {
    margin-right: 0;
  }

  .nav-brand img {
    max-width: 100px;
  }

  .header_list {
    padding: 0 8px;
  }

  .header_search_form .form-select {
    max-width: 88px;
    font-size: 14px;
    background-size: 16px;
    padding-left: 24px;
  }

  .form-select {
    font-size: 14px;
  }

  .header_list.header_droap_box {
    padding: 0 8px;
  }

  .header_list.subscription {
    padding: 0 5px;
  }

  .header_list.header_search_form {
    padding: 0 15px;
    max-width: 25%;
    flex: 0 0 25%;
  }

  .header_search_form .form-control {
    max-width: calc(100% - 110px);
    font-size: 14px;
  }

  .header_search_form .btn.btn_search {
    padding: 0;
  }

  .header_droap_box .btn {
    padding: 10px 7px;
  }

  .header_list.theme_btn.btn.btn-primary {
    padding: 5px 13px;
    font-size: 14px;
  }

  .outline_btn {
    padding: 7px 10px;
    font-size: 14px;
  }

  .header_list.languge {
    max-width: 74px;
    flex: 0 0 74px;
  }
  header {
    padding: 10px 0;
  }

  .header_search_form {
    height: 50px;
  }

  .nav-brand {
    margin-right: 0;
  }

  .nav-brand img {
    max-width: 100px;
  }

  .header_list {
    padding: 0 8px;
  }

  .header_search_form .form-select {
    max-width: 88px;
    font-size: 14px;
    background-size: 16px;
    padding-left: 24px;
  }

  .form-select {
    font-size: 14px;
  }

  .header_list.header_droap_box {
    padding: 0 8px;
  }

  .header_list.subscription {
    padding: 0 5px;
  }

  .header_list.header_search_form {
    padding: 0 15px;
    max-width: 27.5%;
    flex: 0 0 27.5%;
  }

  .header_search_form .form-control {
    max-width: calc(100% - 110px);
    font-size: 14px;
  }

  .header_search_form .btn.btn_search {
    padding: 0;
  }

  .header_droap_box .btn {
    padding: 10px 7px;
  }

  .header_list.theme_btn.btn.btn-primary {
    padding: 5px 13px;
    font-size: 14px;
  }

  .outline_btn {
    padding: 7px 10px;
    font-size: 14px;
  }

  .header_list.languge {
    max-width: 74px;
    flex: 0 0 74px;
  }

  .left_sticky {
    width: 70px;
  }

  .left_sticky a {
    padding: 10px;
    font-size: 13px;
  }

  .banner_capion {
    margin-left: 74px;
  }
  .product_card {
    margin: 0 0px 30px 0px;
  }
  .right_form_area {
    padding: 64px 80px;
}
.fb{
  padding: 12px 10px;
}
  .pay-advt {
    padding: 10px;
    height: 58px;
  }

  .pay-advt span img:not(.checkbox_tab img) {
    margin: 0 5px;
  }

  .pay-advt strong small {
    font-size: 10px;
  }

  .pay-advt .nav_dots {
    right: 0;
  }

  .pay-advt strong {
    font-size: 14px;
  }

  .outline_wrapper {
    padding: 15px 20px 0 20px;
  }

  .list_outer {
    margin: 24px -20px 24px -20px;
  }
  .tracking_head {
    margin: -24px -20px 0px -20px;
    padding: 15px 20px;
  }

  /**==========buyer-pages==========**/

  .buyer_sidebar .chatleftside.border-right {
    flex: 0 0 350px;
    max-width: 350px;
  }

  .chat-buyer-right {
    flex: 0 0 100%;
    max-width: calc(100% - 350px);
  }

  .blur-border-btn,
  .counter-btn.green-btn {
    width: 100px;
  }

  .msg-tip a {
    font-size: 14px;
    padding: 8px 15px;
  }
  .friend-drawer {
    padding: 15px 24px 20px 24px;
    justify-content: space-between;
  }

  .chatleftside-head {
    padding: 15px 24px;
  }

  /* .friend-drawer figure {
    flex: 0 0 100%;
    max-width: calc(100% - 90px);
  } */
  .friend-drawer figure figcaption {
    flex: 0 0 100%;
    max-width: calc(100% - 46px);
  }
  .message-text {
    font-size: 13px;
  }
  .friend-drawer figure figcaption {
    flex: 0 0 100%;
    max-width: calc(100% - 46px);
  }

  .img-md-thumbnail {
    width: 50px;
    flex: 0 0 50px;
  }

  .paymethod span {
    margin-left: 20px;
  }

  .paymethod small {
    font-size: 12px;
  }

  .moreinfo-accordian .accordion-item .accordion-header {
    left: 60px;
    top: 8px;
}

  .moreinfo-accordian {
    margin-top: -10px;
  }

  .buyer-detail-card h5 {
    font-size: 14px;
  }

  .buyer-detail-card strong {
    font-size: 13px;
  }

  .buyer-card-list .buyer_btn .btn {
    margin: 0 5px;
    width: 100px;
    font-size: 14px;
    padding: 9px 10px;
  }
  .simple_text h6 {
    font-size: 16px;
  }

  .friend-drawer figure figcaption h5 {
    font-size: 16px;
  }

  .msg-tip a {
    font-size: 13px;
    padding: 8px 10px;
    height: 36px;
    margin: 5px;
  }

  .location-info-list ul li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .image_gallery_sec .doffo_label {
    top: 56%;
  }
  .post_user_name figure .img_circle {
    width: 40px;
    height: 40px;
  }
  .post_user_name figcaption h5 {
    font-size: 14px;
  }
  .seller-post-review h3 {
    font-size: 24px;
  }
  .seller-post-review .price {
    font-size: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container-fluid {
    max-width: 100%;
    width: 100%;
  }
  .moreinfo-accordian .accordion-item .accordion-header{
    top: 11px;
  }
  .mobile-chat-buyer .img-md-thumbnail {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
  }
  .upload_post img {
    width: 80px!important;
    height: 80px!important;
}
  .ribbon{
    width: 96%;
  }
  .order_caption figure {
    width: 50px;
    height: 50px;
    margin: 0 auto;
}

  .login_with .fb{
    padding: 12px 6px;
  }
  .tw button{
    padding: 12px 9px;
  }
  .login_with button span{
    font-size: 14px!important;
  }
  .login_with button{
    font-size: 14px!important;
  }
  .product_card{
    max-height: 233px;
  }
  .header_contant {
    justify-content: space-between;
  }
  .tabs_outer .tab_outer_heading h2 {
    font-size: 24px;
  }
  .offer_page_wrapper .buyer-service {
    margin: 0 -20px 32px -20px;
  }
  .offer_page_wrapper .tracking_head {
    margin: -20px -20px 0;
  }
  .post_user_name figcaption h5 {
    font-size: 14px;
  }
  .image_gallery_sec .doffo_label {
    top: 56%;
  }
  .seller-post-review h3 {
    font-size: 24px;
  }
  .seller-post-review .price {
    font-size: 24px;
  }

  .image-gallery {
    margin: -20px -20px 0px -20px;
  }
  .contact_sec {
    padding: 20px 0px 35px 0px;
  }
  .all_inner_heading {
    font-size: 22px;
    margin-bottom: 15px;
  }
  .listing_brands_new {
    margin: 0px -20px;
    margin-top: 20px;
    padding: 15px 15px;
  }
  .about_us_sec {
    padding-top: 0px;
  }
  .inner_sec h2 {
    font-size: 30px;
  }
  .inner_sec {
    height: 80px;
  }
  .my-favourite .trans_date_list,
  .my-favourite .trans_date_list figure {
    width: 70px;
    height: 70px;
  }
  .sale_history_sec .theme_btn {
    font-size: 14px;
  }
  .profile_heading figure {
    width: 100px;
    height: 100px;
  }
  .profile_heading .rating_star h2 {
    font-size: 24px;
  }
  .profile_heading .rating_star p {
    font-size: 15px;
  }
  .profile_item_rate li h4 {
    font-size: 19px;
  }
  .profile_item_rate li span {
    font-size: 14px;
  }
  .profile_feedback ul li {
    font-size: 14px;
  }
  .profile_feedback {
    padding: 23px 0px;
  }
  .active_post_slider {
    margin: 0px -20px;
  }
  .active_post_slider .slick-prev {
    left: 94.8%;
  }
  .profile_feedback ul li span {
    width: 25px;
    height: 25px;
    line-height: 27px;
    font-size: 13px;
  }
  .badges_sec li {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .home_price_filter form .filter_dropdowns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .home_price_filter .filter_btn {
    padding-left: 111px;
    margin-top: 10px;
  }
  .home_price_filter .distance_level {
    flex: 0 0 252px;
    max-width: 252px;
  }
  .home_price_filter {
    padding: 18px 20px 18px 20px;
  }
  .tabs_outer .nav-tabs .nav-link {
    padding: 9px 16px;
    font-size: 16px;
  }
  .main_contant .nav.nav-tabs {
    margin-bottom: 20px;
    padding: 12px 18px 18px 18px;
  }
  .tab_product_filter .theme_btn img {
    margin-right: 11px;
    width: 18px;
  }
  .main_contant {
    padding: 30px 0;
  }
  .tab_product_filter h2 {
    font-size: 22px;
    margin: 0;
  }
  .tabs_outer .nav-item .nav-link > span img {
    max-width: 35px;
    min-height: 35px;
  }
  .checkbox_home_tab {
    right: 15px;
  }

  .list_outer {
    margin: 0px -20px 0px -20px;
  }
  .processing_steps {
    padding: 20px 50px 10px 50px;
  }
  .tracking_head {
    margin: -24px -20px 0px -20px;
    padding: 15px 20px;
  }

  .formsticky {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: normal;
  }
  .genrating_price strong {
    margin-right: 15px;
  }
  .toggle_main {
    display: block;
  }
  .dashboard_sidebar .sidebar_overlay {
    z-index: 9;
    opacity: 0.5;
    visibility: visible;
  }
  .dashboard_sidebar .togggle_dashboard {
    top: 0px;
    background: transparent;
    box-shadow: none;
  }

  .dashboard_sidebar .togggle_dashboard img {
    filter: none;
  }
  .dashboard_sidebar .sidebar {
    transform: translateX(0px);
    z-index: 12;
  }

  .dashboard_main .order_detail_box .order_caption p {
    font-size: 14px;
  }

  .manage_address .order_caption strong {
    font-size: 14px;
  }

  .outline_wrapper h2 {
    font-size: 22px;
  }
  .doffo_info {
    padding: 24px 0px;
  }
  .account_step {
    margin-bottom: 10px;
  }
  .account_infor_verfiy {
    margin-bottom: 30px;
  }
  .sidebar {
    transform: translateX(-320px);
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100vh;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    border-radius: 0px;
    right: 0px;
    box-shadow: 0 0 30px rgb(0 0 0 / 20%);
  }
  .sidebar_main {
    margin-top: 0%;
  }
  .sidebar_outer .dashboard_main {
    width: 100%;
    margin-left: 0px;
  }
  .manage_address .sidebar_outer {
    display: initial;
  }

  .make_offer_form ul li:not(.custome_price) {
    flex: 0 0 120px;
    max-width: 120px;
    font-size: 13px;
  }

  .make_offer_form ul li:not(.custome_price) .checkbox_design {
    font-size: \;;
  }

  .custome_price {
    padding-left: 5px;
  }

  .custome_price label {
    font-size: 14px;
  }

  .best_offers_detail {
    display: inline-block;
    margin-bottom: 24px;
    width: 100%;
  }

  .right_form_area {
    padding: 40px 50px;
  }
  .popup_left h5 {
    font-size: 24px;
    padding: 0 0px;
  }
  .popup_left {
    padding: 40px 28px;
  }

  .formsticky {
    background: #f1f1f1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    padding: 50px 20px;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    width: 250px;
    flex-wrap: initial;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    transform: translateX(300px);
  }

  .formsticky .header_list.subscription {
    margin: 0px 0 15px 0;
    display: block !important;
    width: 100%;
    padding: 0;
  }

  .formsticky .header_list.subscription .outline_btn {
    margin: 0 7px;
    font-size: 14px;
    width: 100%;
    display: block;
    margin: 10px 0;
    text-align: center;
  }

  .formsticky .theme_btn {
    width: 100%;
    justify-content: center;
  }

  .formsticky .header_list.languge {
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
  }

  .Mobile_toggle {
    display: block;
    background: transparent;
    margin-right: 0px;
    border: none;
    padding: 5px;
  }
  .header_search_form {
    height: 46px;
    max-width: 35%;
    flex: 0 0 35%;
    padding: 0 15px 0 15px;
  }

  header {
    padding: 15px 0;
  }

  .nav-brand img {
    max-width: 124px;
  }
  .header_search_form .form-control {
    max-width: calc(100% - 106px);
  }
  .nav-brand {
    margin-right: 0px;
  }
  .header_search_form .form-select {
    max-width: 84px;
    background-size: 15px;
    padding-left: 20px;
  }
  .header_search_form .btn.btn_search {
    padding-right: 0;
  }

  .select_market {
    margin-right: 0px;
  }
  .header_droap_box .btn {
    padding: 10px;
  }

  .left_sticky a {
    padding: 10px;
    font-size: 12px;
  }

  .left_sticky {
    width: 64px;
  }

  .header_list.header_droap_box {
    padding: 0 10px;
    margin-left: 0;
  }

  .header_list.subscription {
    padding: 0 10px;
  }

  .banner_capion {
    margin-left: 90px;
    max-width: 480px;
  }

  .banner_capion h1 {
    font-size: 36px;
    margin: 0;
  }

  .banner_capion p {
    font-size: 15px;
    line-height: 20px;
  }
  .overlay_bottom h4 a {
    font-size: 16px;
  }

  .product_card {
    margin-bottom: 30px;
  }

  .tabs_outer .outline-btn.theme_btn.btn {
    margin: 24px auto;
  }

  .footer_nav_block {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 20px;
  }

  .socialfooter {
    flex-wrap: wrap;
  }

  .footer_nav_block.newslatter {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .footer .col-md-3 {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .footer .socialfooter.col-md-9 {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .footer {
    padding: 36px 0;
  }
  .product_info .price {
    font-size: 15px;
  }
  .outline_wrapper {
    padding: 15px 20px 0 20px;
  }
  .btn.btn-primary.add-new-card {
    width: 200px;
  }
  .order_detail_head h4 {
    font-size: 16px;
  }

  .order_detail_head .edit,
  .delete {
    font-size: 14px;
  }
  .outline_wrapper {
    margin-bottom: 36px;
  }

  .order_summary_sidebar {
    margin-bottom: 24px;
  }

  /**============buyer===========**/

  .chatleftside.border-right {
    flex: 0 0 450px;
    max-width: 450px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    max-height: 100vh;
    overflow-y: auto;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transform: translate(-100%);
    background-color: #fff;
    z-index: 9;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }

  .chat-buyer-right {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .paymethod span {
    margin-left: 20px;
  }

  .blur-border-btn,
  .green-btn,
  .red-border-btn {
    width: auto !important;
    min-width: 100px;
    font-size: 14px;
  }

  .location-info-list ul li {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .msg-tip a {
    font-size: 13px;
    padding: 8px 12px;
    height: 36px;
    margin: 0 6px;
  }

  .msg-tip {
    margin-left: -6px;
  }

  .chat-buyer-right {
    border-left: 1px solid #dedede;
  }

  .simple_text h6 {
    font-size: 16px;
  }

  .buyer-service {
    margin-bottom: 10px;
  }
  .buyer_sidebar .chat-buyer-right {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .buyer_sidebar .chatleftside.border-right {
    flex: 0 0 450px;
    max-width: 450px;
    transform: translate(0);
    border-radius: 0px;
    width: 405px;
  }
  .buyer_sidebar .chat-buyer-right {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .friend-drawer {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #dedede;
    padding: 15px 32px;
    width: 100%;
  }
  .buyer_sidebar .button-right {
    position: fixed;
    top: 10px;
    left: 405px;
    background: #33a7cc;
    padding: 8px;
    z-index: 9;
  }

  .buyer_sidebar:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgb(0 0 0 / 70%);
  }
  .buyer_sidebar .chatleftside.border-right .btn.btn_search {
    display: block;
  }
  .buyer_sidebar .chatleftside.border-right .datefx-option {
    display: block;
  }
  .button-right {
    transform: rotate(180deg);
  }
  .buyer_sidebar .button-right {
    transform: rotate(0);
  }

  .chat-panel.chat-byer-panel {
    padding-bottom: 162px;
  }

  .chat-panel.chat-byer-panel.chat-message_list,
  .chat-panel.chat-byer-panel {
    height: calc(100% - 162px);
  }
  .chat-panel.chat-byer-panel.chat-message_list {
    height: calc(100% - 160px);
  }
}

/**===============iphone============**/

@media screen and (max-width: 767px) {
  .boost_duration .ribbon {
    width: 62%;
}
.search-chat-crose-icon {
  right: 27px;
}
.mobile-chat-buyer .buyer-card-list{
  flex-direction: column;
  align-items: normal;
}
/* .moreinfo-accordian .accordion-item .accordion-header {
  left: 60px;
  top: 8px;
} */
.moreinfo-accordian .accordion-item .accordion-header{
  top: 11px;
}
.mobile-chat-buyer .img-md-thumbnail {
  width: 50px;
  height: 50px;
  flex: 0 0 50px;
  max-width: 50px;
}
  .login_with button {font-size: 13px;padding-left:  15px!important;
    padding: 7px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }
  .manage-post-flex  .add_new_btn .btn {
    border-radius: 10px;
    font-size: 14px;
    height: 34px;
}
  .manage-post-flex .post_form {
    margin: 7px 0px;
}
  .manage-post-flex {
    padding: 6px 14px;
    flex-direction: column;
}
  .ribbon .ribbon-content:before{
    left: initial;
  }
  .order_caption figure {
    width: 50px;
    height: 50px;
    margin: 0 auto;
}
  .login_with{
    margin: 0;
    margin-bottom: 20px;
  }
  .Gplus div {
    width: 35px;
    height: 35px;
    padding: 0px!important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 0px!important;
}
.login_with button span{font-size:13px;}
    /* .login_with button span{
      display: none;
    } */
    .login_with{
      justify-content: normal;
    }
.login_with i {font-size: 15px;}

  /* jatin */
  .left_sticky {
    display: none;
  }
  .subscribe_modal .outer h4 span {
    font-size: 35px;
  }
  .taglist a {
    width: calc(50% - 10px);
  }
  .subscribe_modal .outer h4 {
    font-size: 20px;
  }
  .subscribe_modal .outer h3 {
    font-size: 23px;
    margin: 20px 0 0;
    padding: 10px 0;
  }
  .subscribe_modal .outer.active h3 {
    margin: 30px 0 0;
    padding: 20px 0;
  }
  /* Jatin */

  .tabs_outer .tab_outer_heading h2 {
    font-size: 18px;
  }
  .mobile_app {
    margin-top: 14px;
  }
  .success_popup h3 {
    font-size: 25px;
  }
  .success_popup small {
    font-size: 16px;
  }
  .success_popup .theme_btn {
    font-size: 16px;
  }
  .cashout_modal h4 {
    font-size: 20px;
    text-align: center;
  }
  .cashout_modal .cashout_balance h2 {
    font-size: 21px;
  }
  .cashout_balance figure {
    margin-right: 21px;
  }
  .add_card .modal-body {
    padding: 40px 24px 37px 24px;
  }
  .main-heading {
    margin-bottom: 11px;
  }
  .main-heading h2 {
    font-size: 18px;
  }
  .seller-post-review p {
    font-size: 11px;
  }
  .confirm_order_info {
    padding-top: 15px;
  }
  .post_user_name figcaption h5 {
    font-size: 14px;
  }
  .payment_method .description {
    flex-wrap: wrap;
  }
  .payment_social {
    margin-top: 15px;
  }
  .image-gallery {
    margin: -20px -20px 0px -20px;
  }
  .image_gallery_sec .doffo_label {
    top: 48%;
    font-size: 12px;
  }
  .contact_sec {
    padding: 0px 0px 35px 0px;
  }
  .all_inner_heading {
    font-size: 22px;
    margin-bottom: 15px;
  }
  .product_item_horizantal figure {
    width: 70px;
  }
  .offer_product_detail h3 {
    font-size: 22px;
  }
  .offer_product_detail .price {
    font-size: 20px;
  }
  .about_us_sec {
    padding-top: 0px;
    padding-bottom: 20px;
  }
  .inner_sec h2 {
    font-size: 22px;
  }
  .inner_sec {
    height: 70px;
  }
  .transcation_history_sec span {
    font-size: 14px;
  }
  .trans_date_list span strong {
    font-size: 20px;
  }
  .money_add .amount_price {
    font-size: 15px;
  }
  .trans_history_money .money_add .trans_date .trans_title {
    padding-top: 0px;
  }
  .remain_balance span {
    font-size: 18px;
  }
  .remain_balance small {
    font-size: 21px;
  }
  .remain_balance {
    padding: 11px 14px 11px 14px;
  }
  .sale_history_sec .theme_btn {
    font-size: 13px;
    padding: 0px 12px;
    height: 27px;
  }
  /* .trans_history_money .money_add .trans_date {
            max-width: 60%;
            flex: 0 0 60%;
        }
        .likes_btn{
            flex: 1;
        } */
  .manage_address {
    padding: 20px 0px;
  }
  .trans_history_money ul li {
    padding: 9px 5px 9px 9px;
  }
  .trans_history_money .money_add .trans_date .trans_title h3 {
    font-size: 16px;
  }
  .my-favourite .trans_date_list,
  .my-favourite .trans_date_list figure {
    width: 50px;
    height: 50px;
  }
  .profile_heading figure {
    width: 100px;
    height: 100px;
  }
  .profile_heading .rating_star h2 {
    font-size: 20px;
  }
  .profile_heading .rating_star p {
    font-size: 14px;
  }
  .badges_sec li span {
    font-size: 14px;
  }
  .profile_item_rate li h4 {
    font-size: 16px;
  }
  .badges_sec li figure {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    display: block;
  }
  .active_post_slider .slick-prev:before {
    background-size: 5px;
  }
  .active_post_slider .slick-prev,
  .active_post_slider .slick-next {
    width: 26px !important;
    height: 29px !important;
  }
  .active_post_slider .slick-next:before {
    background-size: 5px;
  }
  .profile_feedback ul li {
    margin-right: 4px;
    margin-bottom: 10px;
  }
  .profile_item_rate li span {
    font-size: 14px;
  }
  .profile_feedback ul li {
    font-size: 14px;
  }
  .profile_feedback {
    padding: 23px 0px;
  }
  .active_post_slider {
    margin: 0px -20px;
  }
  .active_post_slider .slick-prev {
    left: 92.8%;
  }
  .profile_feedback ul li span {
    width: 25px;
    height: 25px;
    line-height: 27px;
    font-size: 13px;
  }
  .badges_sec li {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .success_modal {
    padding-left: 0px !important;
  }
  .listing_brands_new .listing_brand_cat:first-child {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .listing_brands_new .listing_brand_cat {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .home_price_filter {
    padding: 23px 6px 18px 6px;
  }
  .home_price_filter form .filter_dropdowns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .home_price_filter .filter_btn {
    padding-left: 96px;
    margin-top: 10px;
  }
  .home_price_filter .filter_btn button {
    font-size: 15px;
    height: 33px;
    padding: 0px 14px;
  }
  .tab_product_filter .theme_btn {
    height: 35px;
    padding: 5px 14px;
  }
  .tab_product_filter .theme_btn::after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 9px solid #ecb213;
  }
  .tabs_outer .nav-tabs .nav-link {
    padding: 8px 16px;
    font-size: 14px;
  }
  .main_contant .nav.nav-tabs {
    margin-bottom: 12px;
    padding: 12px 18px 18px 18px;
  }
  .tab_product_filter .theme_btn img {
    margin-right: 8px;
    width: 15px;
  }
  .main_contant {
    padding: 30px 0;
  }
  .tab_product_filter h2 {
    font-size: 18px;
    margin: 0;
  }
  .tabs_outer .nav-item .nav-link > span img {
    max-width: 35px;
    min-height: 35px;
    display: none;
  }
  .checkbox_home_tab {
    right: 15px;
  }

  .list_outer {
    margin: 0px -20px 0px -20px;
  }
  .tracking_head {
    margin: -24px -20px 0px -20px;
    padding: 15px 20px;
  }
  .dashboard_main .order_detail_box .order_caption p {
    font-size: 14px;
  }

  .formsticky {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .togggle_dashboard {
    width: 42px;
    height: 42px;
  }
  .dashboard_sidebar .sidebar_overlay {
    z-index: 9;
    opacity: 0.5;
    visibility: visible;
  }

  .dashboard_sidebar .togggle_dashboard {
    top: 0px;
    background: transparent;
    box-shadow: none;
  }

  .dashboard_sidebar .togggle_dashboard img {
    filter: none;
  }
  .dashboard_sidebar .sidebar {
    transform: translateX(0px);
    z-index: 12;
  }

  .toggle_main {
    display: block;
  }
  .manage_address .order_caption strong {
    font-size: 14px;
  }
  .manage_address .order_detail_box {
    padding: 15px 15px;
  }
  .outline_wrapper h2 {
    font-size: 20px;
  }
  .sidebar {
    transform: translateX(-320px);
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100vh;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 0px;
    right: 0px;
    box-shadow: 0 0 30px rgb(0 0 0 / 20%);
  }
  .account_infor_verfiy ul li {
    padding: 9px 13px 9px 13px;
  }
  .account_infor_verfiy {
    margin-bottom: 30px;
  }
  .doffo_info {
    padding: 24px 0px;
  }
  .doffo_info h2 {
    font-size: 20px;
  }

  .sidebar_outer .dashboard_main {
    width: 100%;
    margin-left: 0px;
  }
  .order_summary_sidebar {
    margin-bottom: 24px;
  }

  .outline_wrapper {
    margin-bottom: 24px;
  }

  .btn.btn-primary.add-new-card {
    width: 200px;
  }

  .outline_wrapper.pb-5 {
    padding-bottom: 24px !important;
  }

  .outline_wrapper {
    padding: 15px 20px 0 20px;
  }

  .right_form_area {
    padding: 20px 36px;
  }
  .popup_left h5 {
    font-size: 20px;
    padding: 0 0px;
  }
  .popup_left {
    padding: 20px 20px;
  }

  .login_form .theme_btn {
    margin-top: 10px;
  }

  .formsticky {
    background: #f1f1f1;
    justify-content: normal;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    padding: 50px 20px;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    width: 250px;
    flex-wrap: initial;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    transform: translateX(300px);
  }

  .formsticky .header_list.subscription {
    margin: 0px 0 15px 0;
    display: block !important;
    width: 100%;
    padding: 0;
  }

  .formsticky .header_list.subscription .outline_btn {
    margin: 0 7px;
    font-size: 14px;
    width: 100%;
    display: block;
    margin: 10px 0;
    text-align: center;
  }

  .formsticky .theme_btn {
    width: 100%;
    justify-content: center;
  }

  .formsticky .header_list.languge {
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
  }

  .Mobile_toggle {
    display: block;
    background: transparent;
    margin-right: 0px;
    border: none;
    padding: 5px;
  }

  .banner_capion h1 {
    font-size: 24px;
    margin: 0;
  }

  .banner_capion p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  .tabs_outer .nav-item {
    margin: 0 0px 4px 0px;
  }
  .overlay_bottom h4 a {
    font-size: 16px;
  }

  .product_card {
    margin-bottom: 30px;
  }

  .tabs_outer .outline-btn.theme_btn.btn {
    margin: 10px auto 0px auto;
    height: 50px;
    width: 200px;
    font-size: 16px;
  }

  .footer_nav_block {
    flex: 0 0 50%;
    max-width: 50%;
    margin-top: 20px;
  }

  .socialfooter {
    flex-wrap: wrap;
  }

  .footer_nav_block.newslatter {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .footer .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .footer .socialfooter.col-md-9 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 12px 0 0 0;
  }

  .footer {
    padding: 36px 0;
  }
  .product_info .price {
    font-size: 15px;
  }

  .left_sticky {
    width: 60px;
  }

  .left_sticky a {
    padding: 7px;
    font-size: 12px;
  }

  .left_sticky a img {
    max-width: 23px;
  }

  .main_contant {
    padding: 36px 0;
  }

  .info_message {
    padding: 8px 20px;
    font-size: 14px;
  }

  .main_contant .nav.nav-tabs {
    padding: 9px 15px;
  }

  .banner_capion {
    max-width: 450px;
    margin-left: 60px;
  }

  .banner_capion .btn {
    font-size: 14px;
    height: 36px;
  }

  .footer_link a {
    font-size: 15px;
  }

  .header_search_form {
    max-width: calc(100% - 30px);
    flex: 0 0 100%;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    height: 45px;
    margin: 0 15px;
    padding: 0 12px;
  }

  header {
    padding: 10px 0 60px 0;
    position: relative;
  }

  .nav-brand {
    flex: 0 0 20%;
    max-width: 20%;
    margin-right: 0;
  }

  .select_market {
    margin: 0;
    flex: 0 0 45%;
    max-width: 45%;
    padding: 0 10px;
    font-size: 14px;
  }

  .header_list.header_droap_box {
    flex: 0 0 50px;
    max-width: 50px;
    padding: 0;
    margin-left: 0;
    justify-content: flex-end;
  }

  .header_contant {
    justify-content: space-between;
  }

  .popup_left figure {
    margin-top: 50px;
  }

  .make_offer_form ul li:not(.custome_price) {
    flex: 0 0 50%;
    max-width: calc(50% - 20px);
    margin: 10px;
  }

  li.custome_price {
    padding-left: 0px;
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
  }

  .make_offer_form ul {
    padding: 0;
    flex-wrap: wrap;
  }
  .list_outer ul li {
    font-size: 13px;
  }
  .processing_steps {
    padding: 20px 36px 0px 36px;
  }

  .listing_brands_new {
    margin: 0px -20px;
    margin-top: 20px;
  }

  /**============buyer===========**/

  .chatleftside.border-right {
    flex: 0 0 450px;
    max-width: 450px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    max-height: 100vh;
    overflow-y: auto;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transform: translate(-100%);
    background-color: #fff;
    z-index: 10;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }

  .chat-buyer-right {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .paymethod span {
    margin-left: 20px;
  }

  .blur-border-btn,
  .green-btn,
  .red-border-btn {
    width: auto !important;
    min-width: 80px;
    font-size: 14px;
  }

  .location-info-list ul li {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .msg-tip a {
    font-size: 13px;
    padding: 8px 12px;
    height: 36px;
    margin: 0 6px;
  }

  .msg-tip {
    margin-left: -6px;
  }

  .chat-buyer-right {
    border-left: 1px solid #dedede;
  }

  .simple_text h6 {
    font-size: 16px;
  }

  .buyer-service {
    margin-bottom: 10px;
  }
  .buyer_sidebar .chat-buyer-right {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .buyer_sidebar .chatleftside.border-right {
    flex: 0 0 450px;
    max-width: 450px;
    transform: translate(0);
    border-radius: 0px;
    width: 380px;

  }
  .buyer_sidebar .chat-buyer-right {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .friend-drawer {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #dedede;
    padding: 15px 32px;
    width: 100%;
  }
  .buyer_sidebar .button-right {
    position: fixed;
    top: 10px;
    left: 405px;
    background: #33a7cc;
    padding: 8px;
    z-index: 10;
  }

  .buyer_sidebar:after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgb(0 0 0 / 70%);
    z-index: 9;
  }
  .buyer_sidebar .chatleftside.border-right .btn.btn_search {
    display: block;
  }
  .buyer_sidebar .chatleftside.border-right .datefx-option {
    display: block;
  }
  .chatleftside-head {
    padding: 10px 20px;
  }

  .friend-drawer {
    padding: 15px 20px;
  }

  .buyer_sidebar .button-right {
    left: 381px;
  }

  .settings-tray {
    padding: 15px 20px;
  }

  .chat-panel.chat-byer-panel,
  .buyer-service {
    padding: 15px 20px;
  }
  .img-md-thumbnail {
    width: 50px;
    flex: 0 0 50px;
    border-radius: 8px;
  }

  .buyer-thumb-outer figcaption {
    flex: 0 0 100%;
    max-width: calc(100% - 50px);
  }

  .buyer-card-list .buyer_btn .btn {
    margin: 0 5px;
  }

  .buyer-card-list .buyer-thumb-outer {
    flex: 0 0 100%;
    max-width: 100%;
}

.moreinfo-accordian .accordion-item .accordion-header {
  left: 90px;
  top: 0;
}

  .moreinfo-accordian {
    margin-top: -10px;
  }


  .buyer-card-list .buyer_btn {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 7px 4px;
}

.buyer-card-list .buyer_btn a{
  width: 100%;
}
  .right_btn_service .btn-warning,
  .right_btn_service .bg-transparent {
    font-size: 14px;
    min-width: 80px;
    margin: 0 6px;
  }

  .msg-tip a {
    margin: 5px;
  }

  .msg-tip {
    margin-bottom: 10px;
  }

  .chat-panel.chat-byer-panel {
    padding-bottom: 180px !important;
  }

  .button-right {
    transform: rotate(180deg);
  }
  .buyer_sidebar .button-right {
    transform: rotate(0);
  }

  .post_user_name figure .img_circle {
    width: 40px !important;
    height: 40px !important;
  }

  .offer_page_wrapper .buyer-service {
    margin: 0 -15px 32px -15px;
  }
  .offer-right_btn_service a {
    margin: 0 5px;
  }
  .offer-right_btn_service .nav_dots {
    margin-left: 10px;
  }
  .offer-right_btn_service a {
    margin: 0 7px;
  }

  .offer-page-outline .money_add .trans_date {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offer-page-outline .money_add .likes_btn {
    max-width: 100%;
    flex: 0 0 100%;
    margin: 10px 0;
  }

  .offer-page-outline .money_add .manage_post_ul {
    flex-wrap: wrap;
  }

  .offer_page_wrapper .likes_btn .btn {
    font-size: 12px;
    height: 30px;
    min-width: auto;
    margin: 0 5px;
  }

  .contact_sec .contact_sec {
    display: none;
  }
  .location-picker img {
    width: 16px;
    margin-right: 6px;
  }
  .chat-panel.chat-byer-panel {
    position: relative;
    height: calc(100% - 170px);
  }
  .chat-panel.chat-byer-panel.chat-message_list {
    height: calc(100% - 178px);
}

.login_with{margin: 0 -10px; flex-wrap: wrap;}
.login_with .Gplus, .login_with > span {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0 10px;
  justify-content: center;
  margin-bottom: 10px;
}
.applied_coupon.row .theme_btn {min-width: 150px;margin-top: 10px;}
.review-offer-address .order_detail_box {margin-bottom: 10px!important;}


}

/**===============mobile============**/

@media screen and (max-width: 575px) {
  .login_with{margin: 0px;}

  .login_with .Gplus, .login_with > span{margin-left:0; margin-right:0;}

  .offer_page_wrapper .tracking_head {
    margin: -15px -15px 0;
  }
  .hint-status {
    text-align: center;
  }
  .mobile-offer-dialog .MuiDialogActions-root{
    flex-direction: column;
    margin-right: 0px!important;
  }
  .mobile-offer-dialog .MuiDialogActions-root .css-e53awj-MuiStack-root{
    width: 100%;
  }
  .mobile-offer-dialog .MuiDialog-paperFullWidth {
    /* background-color: #000!important; */
    margin: 0px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    border-radius: 40px 40px 0px 0px;
  }
  .mobile-offer-dialog .MuiDialog-paperFullWidth .MuiTypography-h6{
    text-align: center;
  }
  .friend-drawer figure{
    flex: 0 0 75%;
    max-width: 75%;
  }
  .schedule-meetup-modal .form-control {
    height: 45px;
}
  .panel_bottom .type-tool {
    top: 0px !important;
  }
  .buyer_detail_contant ul li span{
    width: 120px;
  }
  .accepted-offer-main-div-cs {
    padding: 0px 10px 12px;
    padding-left: 0px;

}
  .buyer-detail-card h5 span{
    font-size: 14px;
    padding: 0px 3px;
  }
  .ChatContent .chat-outerbx{
    padding: 15px;
  }
  .buyer-detail-card strong{
    font-size: 15px;
  }
  .remaining-boost img{
    max-width: 103px;
  }
  .remaining-boost li{
    max-width: 100%;
 width: 100%;
  }
  .image_gallery_sec img {
    height: 250px;
    object-position: right;
}
.boost_duration .ribbon {
  width: 100%;
}
  .upload_post img {
    width: 60px!important;
    height: 60px!important;
}
.upload_post button svg{
  font-size: 13px!important;
}

.upload_post button{
  padding: 4px;
  top: -2px;
  right: 6px;
}
  .chat-box-left-cs{
    height: 600px;
  }
  .custom-date-picker  .MuiFormControl-root {
    width: 100%;
}
  .ribbon{
    width: 100%;
  }
  .order_caption figure {

    border-radius: 4px;
}
.order_caption figure img{
  border-radius: 4px;
}
  .react-reveal{
    padding: 20px 20px 18px;
  }
  .subscription_modal .modal-dialog{
    border-radius: 7px;
  }
  .MuiDialogTitle-root{
    padding: 8px 24px;
  }
  .MuiTypography-h6{
    font-size: 1.1rem;
  }
  .tabs_outer .nav-tabs .nav-link{
  border-radius: 5px;
  }
  .popup_left h5{
    margin-bottom: 0px;
  }
  /* Jatin */
  .master_radio {
    font-size: 16px;
  }
  .modal-body {
    padding: 20px 10px;
  }
  .subscribe_modal .modal-title {
    text-align: center;
    line-height: 25px;
  }
  .subscribe_modal .modal-title span {
    font-size: 20px;
  }
  .trans_btn,
  .modal-body .theme_btn.theme_btn {
    font-size: 15px;
  }
  .text-large {
    font-size: 16px;
  }
  .modal-title {
    font-size: 18px;
  }
  .subscribe_modal .modal-title span {
    font-size: 22px;
  }
  .subscribe_modal .outer {
    padding: 15px 10px 0;
  }
  .upload_picture {
    min-height: 150px;
  }
  .upload_picture img {
    max-width: 50px;
  }
  .upload_picture h2 {
    font-size: 18px;
    margin: 10px 0;
  }
  .verification_modal form label {
    font-size: 15px;
    margin-bottom: 6px;
  }
  .upload_picture h5 {
    font-size: 15px;
  }
  /* Jatin */

  .home_price_filter form .filter_dropdowns,
  .listing_brands_new .listing_brand_cat {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .active_post_slider .product_card {
    margin: 0 5px 5px 0px;
  }
  .product_item_horizantal {
    flex-direction: column;
    align-items: normal;
  }
  .offer_product_detail {
    padding-left: 0px;
  }
  .eye_icon {
    font-size: 14px;
  }
  .right_btn_service .btn,
  .buyer_btn .btn,
  .counterbtn .btn {
    height: 36px;
  }

  .success_popup figure {
    max-width: 150px;
    margin: 0 auto;
    display: block;
  }

  .image_gallery_sec .doffo_label {
    display: none;
  }
  .image-gallery {
    margin: -15px -15px 0px -15px;
  }
  .payment_method ul li {
    margin-right: 0px;
  }
  .payment_method .payment_list li a {
    width: 40px;
    height: 40px;
  }
  .payment_social ul li a {
    font-size: 14px;
  }
  .payment_method .payment_list li a img {
    width: 22px;
  }
  .image-gallery-thumbnails-wrapper {
    padding: 16px 12px 20px 12px;
  }
  .image-gallery-thumbnail {
    border: none;
    width: 50px;
    height: 50px;
  }
  .manage_post_ul {
    flex-direction: column;
    align-items: normal;
  }
  .about_us_sec p {
    font-size: 14px;
  }
  .outline_wrapper .nav-tabs .nav-item .nav-link {
    font-size: 14px;
    padding: 4px 14px;
  }
  .outline_wrapper .nav-tabs li.nav-item {
    margin-right: 15px;
  }
  .create_post_sec .upload_post {
    height: 231px;
  }
  .remain_balance small {
    font-size: 16px;
  }
  .remain_balance span {
    font-size: 16px;
  }
  .remain_balance {
    padding: 8px 14px 9px 14px;
  }
  /* .trans_history_money .trans_title span img {
            width: 13px;
        } */
  .trans_history_money .money_add .trans_date {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .trans_history_money .money_add .trans_date {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .money_add .amount_price {
    font-size: 13px;
    position: absolute;
    right: 13px;
    top: 18px;
  }
  .likes_btn {
    flex: 1;
    margin-top: 9px;
  }
  .sale_history_sec .theme_btn {
    padding: 0px 13px;
    height: 27px;
    display: flex;
    font-size: 10px;
    margin-top: 9px;
  }
  .trans_history_money .money_add .trans_date .trans_title h3 {
    font-size: 14px;
  }
  .trans_history_money .trans_title span {
    margin-right: 7px;
  }
  .trans_history_money ul li {
    padding: 5px 5px 5px 4px;
    margin-bottom: 10px;
    position: relative;
  }
  .sale_history_sec .trans_date_list {
    width: 50px;
    height: 50px;
  }
  .trans_date_list span strong {
    font-size: 17px;
  }
  .trans_date_list {
    width: 55px;
    height: 55px;
  }
  .trans_date_list figure {
    width: 50px;
    height: 50px;
  }
  .trans_history_money .money_add .trans_date .trans_title {
    margin-left: 10px;
    padding-top: 0px;
  }
  .trans_history_money .money_add .trans_date .trans_title h3 {
    font-size: 14px;
  }
  .manage_address {
    padding: 20px 0px;
  }
  .tooltip {
    display: none;
  }
  .profile_item_rate li {
    padding: 5px 17px;
    margin-bottom: 11px;
  }
  .profile_item_rate ul {
    margin: 17px 0px 19px 0px;
    flex-wrap: wrap;
  }
  .order_payment_method span {
    font-size: 13px;
  }
  .order_payment_method small img {
    margin-right: 6px;
  }
  .listing_brands_new {
    margin: 0px -15px;
    padding: 18px 20px;
    margin-top: 15px;
    flex-direction: column;
  }
  .listing_brands_new .listing_brand_cat:first-child {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .home_price_filter .filter_btn {
    margin-top: 10px;
    padding-left: 0;
    text-align: center;
  }

  .home_price_filter form .filter_dropdowns .filter_product,
  .home_price_filter .distance_level {
    flex-direction: column;
    align-items: normal !important;
  }
  /* #toggleshow body{
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #000;
        } */
  .home_price_filter {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 9999;
    left: 0;
    right: 0;
    border-radius: 0px;
    box-shadow: 0 0.071rem 0.71rem 0 rgb(0 0 0 / 20%);
  }
  .price_level > .filter_product .form-select {
    margin-bottom: 12px;
  }
  .form-label {
    margin-bottom: 9px;
  }
  .outline_wrapper {
    padding: 10px 15px 0 15px;
  }

  .outline_wrapper h2 {
    font-size: 18px;
  }
  .pay-advt {
    padding: 15px;
    height: 50px;
  }
  .pay-advt .nav_dots {
    width: 24px;
    padding: 5px 10px;
    box-sizing: border-box;
    right: 2px;
    top: 7px;
    box-shadow: none;
  }
  .checkbox_home_tab {
    width: 14px;
    height: 14px;
    right: 8px;
  }
  li.custome_price {
    padding-left: 0px;
    flex: 0 0 250px;
    max-width: 250px;
  }
  .account_step {
    margin-bottom: 4px;
  }
  .rating_star h2 {
    font-size: 18px;
  }

  .profile_detail figure {
    width: 100px;
    height: 100px;
  }
  .sidebar_sie_menu h5 {
    margin-bottom: 3px;
  }
  .sidebar_sie_menu ul li a,
  .sidebar_notify .email_notify {
    padding: 8px 19px 8px 48px;
  }
  .sidebar_sie_menu {
    padding: 17px 0px 8px 0px;
  }
  .sidebar_sie_menu h5 {
    font-size: 15px;
  }

  .account_step .order_detail_head h4 {
    font-size: 16px;
  }
  .my_account_sec .form-control {
    height: 40px;
  }
  .account_infor_verfiy ul li {
    font-size: 14px;
  }
  .doffo_info p {
    font-size: 14px;
    line-height: 23px;
  }
  .account_step .last_name_user {
    margin-left: 0px;
  }
  .my_account_sec .myaccount_group {
    flex: 1;
  }
  .doffo_info {
    padding: 16px 0px;
  }
  .my_account_sec .myaccount_group {
    flex-wrap: wrap;
  }
  .my_account_sec .myaccount_group .form_field {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .popup_left {
    padding: 12px 20px;
}
.crose_btn{
  margin: 12px;
}
  .popup_left h5 {
    padding-right: 50px;
    text-align: left;
    font-size: 16px;
}

  .right_form_area h4 {
    font-size: 20px;
  }

  .subscription {
    flex-wrap: wrap;
  }

  .popup_left {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .right_form_area {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 20px;
  }

  .popup_left figure {
    margin-top: 30px;
  }

  .banner_capion p {
    display: none;
  }

  .banner_capion {
    max-width: 100%;
    margin-left: 0;
    text-align: center;
    right: 0;
    left: 0;
  }

  .banner_capion h1 {
    font-size: 20px;
    margin: 0 0 10px 0;
  }

  .left_sticky a {
    padding: 5px;
  }

  .left_sticky {
    width: 54px;
  }

  .banner_capion .btn {
    font-size: 13px;
  }

  .info_message {
    font-size: 11px;
    padding: 6px 10px;
    line-height: 1.2;
  }

  .main_contant {
    padding: 24px 0;
  }

  .tabs_outer .nav-tabs .nav-link {
    padding: 7px 7px;
    font-size: 11px;
}
  .tab_product_filter {
    margin-bottom: 16px;
  }
  .tabs_outer .tab_outer_heading h2 {
    font-size: 16px;
  }
  .tabs_outer .nav-item .active {
    border: 2px solid #33a7cc !important;
  }
  .tabs_outer .nav-item {
    padding: 4px;
  }
  .tab_product_filter h2 {
    font-size: 16px;
  }
  .tab_product_filter .theme_btn {
    height: 33px;
  }
  .tabs_outer .nav-tabs .nav-link.active .checkbox_home_tab:before {
    width: 2px;
    height: 8px;
    left: 6px;
    top: 2px;
  }
  .tabs_outer .tab_outer_heading {
    padding: 17px 8px 0px 8px;
  }
  .tabs_outer .nav-tabs .nav-link.active .checkbox_home_tab:after {
    width: 4px;
    height: 2px;
    left: 2px;
    top: 6px;
  }
  .tabs_outer .nav-item .nav-link span img {
    max-width: 30px;
    margin: 0 0 2px 0;
  }

  .tabs_outer .nav-item .nav-link:after {
    margin: -6px;
  }

  .product_outer_main .col-6 {
    /* flex: 0 0 100%; */
    /* max-width: 100%; */
    /* width: 100%!important; */
    padding: 5px;
    margin: 0;
  }

  .overlay_bottom {
    padding: 24px 10px 10px 10px;
  }

  .product_card {
    margin-bottom: 0px;
    margin: 0;
  }
  .product_card {
    border-radius: 12px;
    max-height: 240px;
  }
  .product_card figure img{
    height: 240px;
  }
  #form-dialog-title{
    padding: 10px 18px;
  }
  #form-dialog-title .MuiTypography-h6{
    font-size: 1.1rem;
  }
  .app_info {
    padding: 15px 0;
  }

  .app_info_row {
    flex-direction: column;
    justify-content: center;
  }

  .img_app_fream {
    margin: 10px 0;
  }

  .mobile_app {
    padding: 24px 0;
  }

  .app_detail {
    padding: 0;
  }

  .footer_nav_block.newslatter {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .bottom_footer_row {
    flex-direction: column;
  }

  .bottom_footer {
    padding: 15px 0;
  }

  .footer_block_right_card {
    margin: 10px 0 0 0;
  }
  .overlay_bottom {
    padding: 24px 10px 10px 10px;
  }

  .overlay_top {
    padding: 10px 10px 0 10px;
    top: 0;
  }

  .boost_badge {
    font-size: 10px;
  }

  .sold_badge {
    font-size: 10px;
  }

  .overlay_bottom h4 a {
    font-size: 14px;
    line-height: 1.2 !important;
  }

  .overlay_bottom h4 {
    line-height: 0.8;
  }

  .product_outer_main .row {
    margin: 0 -5px;
  }
  .main_contant .nav.nav-tabs {
    padding: 8px 8px;
  }
  .tabs_outer .outline-btn.theme_btn.btn {
    margin: 15px auto 0px auto;
    height: 45px;
    width: 186px;
    font-size: 15px;
  }

  .header_search_form {
    padding: 0 15px 0 15px;
    height: 40px;
  }

  .header_search_form .form-select {
    max-width: 84px;
    padding-right: 5px;
    font-size: 13px;
    background-size: 14px;
    padding-left: 20px;
  }

  .header_search_form .form-control {
    max-width: calc(100% - 105px) !important;
    font-size: 13px;
  }

  .header_search_form .btn.btn_search {
    padding: 0;
  }

  .header_droap_box .btn {
    padding: 0px;
  }

  .select_market {
    flex: 0 0 auto;
    max-width: initial;
    padding: 0;
  }
  .notification.dropdown .btn:before {
    right: 2px;
  }

  .nav-brand {
    flex: 0 0 100%;
    max-width: calc(100% - 120px);
  }

  .header_list.header_droap_box {
    flex: 0 0 40px;
    max-width: 40px;
    justify-content: flex-end;
  }
  .nav-brand img {
    max-width: 110px;
  }
  .subscription_modal {
    padding: 0px !important;
  }
  .login_with a {
    padding: 0 5px;
  }

  /* .login_with {
    margin: 10px -5px;
  } */
  .order_detail_box {
    padding: 10px 15px;
  }

  .order_detail_head h4 {
    font-size: 14px;
  }

  .order_detail_head .edit,
  .delete {
    font-size: 13px;
    padding: 5px;
  }

  .order_caption p {
    font-size: 14px;
  }

  .delete img {
    max-width: 15px;
  }

  .order_caption {
    min-height: 50px;
  }
  .order_detail_box {
    padding: 10px 15px;
  }

  .order_detail_head h4 {
    font-size: 14px;
  }

  .order_detail_head .edit,
  .delete {
    font-size: 13px;
    padding: 5px;
  }

  .order_caption p {
    font-size: 14px;
  }

  .delete img {
    max-width: 15px;
  }

  .order_caption {
    min-height: 50px;
    padding: 0;
  }

  .offer_product_detail h3 {
    font-size: 18px;
  }

  .product_item_horizantal figure {
    width: 75px;
    margin-right: 6px;
  }

  .offer_product_detail small {
    font-size: 14px;
  }

  .offer_product_detail .price {
    font-size: 16px;
  }
  .listing_condition .description p {
    font-size: 14px;
  }
  .shipping_btn .theme_btn {
    padding: 4px 10px;
  }
  .listing_condition li {
    padding: 5px 0;
  }
  .matherd_select h4 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 5px;
  }

  .make_offer_form ul li:not(.custome_price) {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 10px 0;
  }

  .custome_price {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 10px;
    padding-left: 0;
  }

  .genrating_price strong {
    font-size: 24px;
    margin: 0;
    padding: 0 0 15px 0;
    margin-right: 18px;
  }

  form.make_offer_form h5 {
    margin: 0;
  }

  .processing_steps {
    padding: 20px 10px 20px 10px;
  }

  .list_outer ul li {
    font-size: 13px;
  }

  .processing_steps ul li figure {
    width: 54px;
    height: 54px;
    padding: 10px;
  }

  .processing_steps:after {
    top: 35px;
  }
  .processing_steps ul li span {
    font-size: 13px;
  }
  .listing_condition li {
    font-size: 14px;
  }

  .order_detail_box {
    margin-bottom: 15px;
  }
  .processing_steps {
    padding: 10px 10px 10px 10px;
  }

  .tracking_head {
    margin: -15px -15px 0px -15px;
    padding: 15px 20px;
  }

  .list_outer ul li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .list_outer {
    margin: 0px -15px 0px -15px;
    padding: 10px;
  }

  /**=============buyer=========**/

  .buyer-thumb-outer {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .right_btn_service {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 10px 0 0 -6px;
  }

  .chat-panel.chat-byer-panel,
  .buyer-service {
    padding: 15px;
  }

  .buyer-cardinfo {
    margin-bottom: 20px;
    flex-wrap: wrap;
  }

  .buyer-card-list .buyer-thumb-outer {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .buyer-card-list {
    flex-wrap: wrap;
  }

  .buyer-card-list .buyer_btn {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 10px 0 10px -6px;
    justify-content: flex-start;
  }

  .moreinfo-accordian {
    margin-top: 0;
    text-align: left;
  }

  .moreinfo-accordian .accordion-item {
    text-align: left;
  }

  /* .moreinfo-accordian .accordion-item .accordion-header {
    left: 0;
  } */

  span.card-pay {
    margin-left: 10px;
  }

  .img-md-thumbnail {
    max-width: 40px;
    flex: 0 0 40px;
  }

  .buyer-thumb-outer figcaption {
    flex: 0 0 100%;
    max-width: calc(100% - 50px);
  }

  .counterbtn {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 10px 0 0 0;
  }

  .meet_description .buyer-thumb-outer {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .shipping_info_btn .btn {
    width: 100% !important;
    margin: 5px 0;
  }

  .shipping_info_btn .blur-border-btn {
    width: 100% !important;
    margin: 5px 0;
    padding: 8px 10px;
  }

  .shipping_info_btn {
    margin: 10px 0;
  }

  .meet-up-location-head {
    flex-direction: column;
  }

  .date-time {
    background: #eee;
    padding: 2px 10px;
    border-radius: 15px;
    font-size: 11px;
    margin: 5px 0 0;
  }

  .location-info-list ul li {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 5px 0;
  }

  .theme_btn.btn.reschedule {
    margin: 15px 0 10px 0;
  }
  .location-info-list ul {
    margin: 0;
  }
  .button-right {
    margin-right: 15px;
  }
  .msg-tip a {
    padding: 3px 10px;
    height: auto;
    margin: 2px 5px;
  }

  .msg-tip {
    margin: 5px 0;
    padding: 0 15px;
  }

  .type-tool {
    padding: 6px 15px;
    height: 50px;
  }

  .buyer_sidebar .chatleftside.border-right {
    flex: 0 0 280px;
    max-width: 270px;
    transform: translate(0);
  }

  .datefx-option .dropdown {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  .friend-drawer {
    position: relative;
  }

  .date {
    width: 100%;
    text-align: right;
    display: inline-block;
    padding: 0;
    font-size: 11px;
  }

  .buyer_sidebar .chatleftside.border-right .datefx-option {
    width: 100%;
  }

  .message-text {
    font-size: 13px;
  }

  .friend-drawer {
    padding: 10px 14px;
}

  .friend-drawer figure figcaption h5 {
    font-size: 16px;
  }

  .buyer_sidebar .button-right {
    left: 270px;
    padding: 5px;
  }

  .friend-drawer figure figcaption {
    flex: 0 0 100%;
    max-width: calc(100% - 36px);
  }

  .friend-drawer figure .img_circle {
    width: 36px;
    height: 36px;
    border-width: 1px;
  }

  .message-text {
    font-size: 12px;
  }

  .friend-drawer figure figcaption h5 {
    font-size: 15px;
  }

  .buyer_sidebar .chatleftside.border-right .btn.btn_search {
    padding: 0;
  }

  .buyer_sidebar .chatleftside.border-right .btn.btn_search img {
    width: 20px;
  }

  .chatleftside-head {
    height: 60px;
  }

  .img_circle {
    width: 36px;
    height: 36px;
  }

  .chating_list li.sender-msg {
    padding-left: 30px;
  }
  .chating_list li {
    padding-right: 30px;
    flex-wrap: wrap;
  }
  .buyer-detail-footer{
    flex-wrap: wrap;
  }
  .chat-panel.chat-byer-panel {
    position: relative;
    height: calc(100% - 207px);
  }
  .gride_mrketing {
    width: 26px;
    overflow: hidden;
  }
 .review-ul li {font-size: 14px;line-height: 1.5;}
 .review-ul li strong {font-size: 13px;}
}

/* Jatin */
@media screen and (max-width: 475px) {
  .subscribe_modal .outer.active h3,
  .subscribe_modal .outer h3 {
    font-size: 13px;
    margin: 10px 0 0;
    padding: 10px 0;
  }
  .subscribe_modal .outer h4 span {
    font-size: 22px;
  }
  .subscribe_modal .outer h4 {
    font-size: 16px;
  }
}

@media (max-width: 420px) {
  /* .likes_btn {
            flex: 1 1;
            flex-direction: column;
        } */
  .sale_history_sec .theme_btn {
    margin-bottom: 6px;
  }
}
