.mark-sold-image{
    width:50px;
    height:50px;
    border: 2px solid gray;
    border-radius: 50%;
}

.select-buyer-content{
    border: 1px solid #e9e9e9;
    padding: 12px 0px;
    border-radius: 11px;
}

.inner-span-content{
    margin-left:10px;
}