.manage-post-img {
  height: 100px;
  width: 100px;
  border-radius: 10px;
}

/* .manage-sold-Product {
  background-color: "rgb(210 163 32 / 60%)";
  text-align: "center";
  color: "#ffffff";
  font-weight: "bold";
  border-radius: "8px";
  position: "absolute";
  top: "5px";
  left: "19px";
  border: "1px solid rgb(210 163 32 / 60%)";
} */

/* //ribbon trick */
.non-semantic-protector {
  position: relative;
  z-index: 1;
  width: 100%;
}
.ribbon {
  font-size: 16px !important;
  width: 75%;
  /* position: relative; */
  background: #31abd3;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  position: absolute;
  top: -25px;
  left: -35px;
}
/* .ribbon:before, */
.ribbon:after {
  content: "";
  position: absolute;
  /* display: block; */
  bottom: -1em;
  border: 11px solid #19aedf;
  z-index: -1;
}
.ribbon:before {
  background: transparent;
  /* left: -2em; */
  /* border-right-width: 1.5em;
  border-left-color: transparent; */
}
.ribbon:after {
  right: -21px;
  border-left-width: 1.5em;
  border-right-color: transparent;
}
.ribbon .ribbon-content:before,
.ribbon .ribbon-content:after {
  content: "";
  position: absolute;
  display: block;
  border-style: solid;
  border-color: #0283ad transparent transparent transparent;
  bottom: -1em;
}
.ribbon .ribbon-content:before {
  left: 0;
  border-width: 1em 0 0 1em;
}
.ribbon .ribbon-content:after {
  right: 0;
  border-width: 1em 1em 0 0;
}
.manage-post-heading .manage-pl {
  font-size: 19px;
}